<div class="container">
	<div class="form-group">
        <h1>Acceso Administrador</h1>
        <div class="form-row">
            <div class="col-sm">
              <p class="pgreen">
                <input type="text" [(ngModel)]="selUserL" name="selectedUser" placeholder="Usuario">
              </p>
              <p class="pgreen">
                <input type="password" [(ngModel)]="selPassL" name="selectedPass" placeholder="Password">
              </p>
              <p><button class="btn btn-success" (click)="login()">Acceder</button></p>
            </div>
        </div>
	</div>
	
</div>

<div class="container">
	
	<div class="form-group">
        <h1>Mapa Web</h1>
		<ul>
            <li><a [routerLink]="['../']" >Inicio</a></li>
			<li>Actividades
				<ul>
                    <li><a [routerLink]="['../actividadesTIC']">Actividades TIC</a></li>
					<li><a [routerLink]="['../porcentaje']">Porcentaje de Actividades TIC</a></li>
				</ul>
            </li>
            <li>Empresas
				<ul>
                    <li><a [routerLink]="['../directorio']">Directorio de Empresas</a></li>
                    <li><a [routerLink]="['../evolucion']">Evolución de las Empresas </a></li>
                    <li><a [routerLink]="['../mapaTIC']">Mapa TIC</a></li>
				</ul>
			</li>
			<li>Perfiles Profesionales y Competencias
				<ul>
					<li><a [routerLink]="['../perfiles']">Perfiles Profesionales TIC</a></li>
					<li><a [routerLink]="['../competencias']">Competencias TIC</a></li>
					<li><a [routerLink]="['../encuesta']">Encuesta Profesional TIC</a></li> 
				</ul>
            </li>
            <li><a [routerLink]="['../memorias']" >Memorias Anuales</a></li>
            <li>Información
				<ul>
					<li><a [routerLink]="['../aviso']">Aviso Legal</a></li>
                    <li><a [routerLink]="['../politica']">Política de Privacidad</a></li>
                    <li><a [routerLink]="['../mapaweb']">Mapa Web</a></li> 
					<li><a [routerLink]="['../contacto']">Contacto</a></li> 
				</ul>
            </li>
		</ul>
	</div>
	
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Encuestas } from 'src/app/models/Encuestas';
import { Submission } from 'src/app/models/Submission';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncuestasService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    saveEncuesta(enc: Encuestas ) {
      return this.http.post(`${this.API_URI}/encuestas/questions`, enc);
    }
    // tslint:disable-next-line: typedef
    saveSubmission(sub: Submission ) {
      return this.http.post(`${this.API_URI}/encuestas/submissions`, sub);
    }
    // tslint:disable-next-line: typedef
    getCounter() {
      return this.http.get(`${this.API_URI}/encuestas/counter`);
    }
}

import { Component, OnInit } from '@angular/core';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpCat } from 'src/app/models/EmpCat';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { Localidad } from 'src/app/models/Localidad';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {

    // Numero de empresas total seleccionado
    numEmpresas!: number;
    // Numero total de empresas
    totalEmpresas!: number;
    // Listado de categorias TIC
    categorias: CategoriaTIC[] = [];
    // Listado de activdades TIC
    actividades: ActividadTIC[] = [];
    // Listado de empresas completo
    empresasAll: EmpresaCompleta[] = [];
    // Listado de empresas selecionado
    empresasSelect: EmpresaCompleta[] = [];
    // Listado de localidades seleccionadas
    localidades: Localidad[] = [];
    // Listado de localidades de toda Extremadura
    localidadesExtremadura: Localidad[] = [];
    // Listado de localidades de Badajoz
    localidadesBadajoz: Localidad[] = [];
    // Listado de localidades de Caceres
    localidadesCaceres: Localidad[] = [];
    // Listado de relaciones actividad-empresa
    empAct: EmpAct[] = [];
    // Listado de relaciones categoria-empresa
    empCat: EmpCat[] = [];
    // Variables seleccionadas del formulario
    selCat: CategoriaTIC[] = [];
    selAct: ActividadTIC[] = [];
    selName!: string;
    selProv!: string;
    selLoc!: string;
    selCons!: string;
    selDis!: string;
    selPT!: string;
    selEmpCat: EmpCat[] = [];
    selEmpAct: EmpAct[] = [];
    isDis = false;


    constructor(private es: EmpresasService, private cTICs: CategoriasTICService, private aTICs: ActividadesTICService,
                private ea: EmpActService, private ec: EmpCatService) {    }

    async ngOnInit(): Promise<void> {
        window.scrollTo(0, 0);
        this.loadData();
    }

    loadData(): void {
        this.es.getEmpresasComp().subscribe(
            res => {
                this.empresasAll = res as EmpresaCompleta[];
                this.totalEmpresas = this.empresasAll.length;
                this.empresasSelect = this.empresasAll;
                this.numEmpresas = this.empresasSelect.length;
                this.empresasSelect = this.empresasSelect.sort((n1, n2) => {
                if (n1.titulo > n2.titulo) {
                    return 1;
                }
                if (n1.titulo < n2.titulo) {
                    return -1;
                }
                return 0; });
                },
            err => console.log(err)
            );
        this.es.getLocalidades().subscribe(
                res => {
                this.localidades =  res as Localidad[];
                this.localidades.shift();
                this.localidadesExtremadura = this.localidades;
                },
                err => console.log(err)
        );

        this.es.getLocalidadesBadajoz().subscribe(
            res => {
                this.localidadesBadajoz =  res as Localidad[];
            },
            err => console.log(err)
        );

        this.es.getLocalidadesCaceres().subscribe(
            res => {
                this.localidadesCaceres =  res as Localidad[];
            },
            err => console.log(err)
        );

        this.cTICs.getCategoriasTIC().subscribe(
            res => {
                this.categorias =  res as CategoriaTIC[];
            },
            err => console.log(err)
        );

        this.aTICs.getActividadesTIC().subscribe(
            res => {
                this.actividades =  res as ActividadTIC[];
            },
            err => console.log(err)
        );

        this.ea.getRelations().subscribe(
            res => {
                this.empAct =  res as EmpAct[];
            },
            err => console.log(err)
        );

        this.ec.getRelations().subscribe(
            res => {
                this.empCat =  res as EmpCat[];
            },
            err => console.log(err)
        );
    }

    // Funcion controladora de los cambios en las empresas seleccionadas
    onChange(): void {

        this.empresasSelect = this.empresasAll;

        if (this.selProv !== undefined && this.selProv !== 'undefined') {

            if (this.selProv === 'Badajoz') {
                const cac: Localidad = this.localidadesCaceres[0];
                if (this.localidades.includes(cac)) {  this.selLoc = 'undefined'; }
                this.localidades = this.localidadesBadajoz;
            }
            if (this.selProv === 'Cáceres') {
                const bad: Localidad = this.localidadesBadajoz[0];
                if (this.localidades.includes(bad)) {  this.selLoc = 'undefined'; }
                this.localidades = this.localidadesCaceres;
            }
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.provincia === this.selProv);
        } else {
            this.localidades = this.localidadesExtremadura;
        }


        if (this.selLoc !== undefined && this.selLoc !== 'undefined') {
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.localidad === this.selLoc);
        }

        if (this.selName !== undefined && this.selName !== '') {
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.titulo.toLowerCase().indexOf(this.selName.toLowerCase()) >= 0);
        }

        if (this.selPT !== undefined && this.selPT !== 'undefined') {
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.puraTIC.toLowerCase().indexOf(this.selPT.toLowerCase()) >= 0);
        }

        if (this.selCons !== undefined && this.selCons !== '') {
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.constitucion.toLowerCase().indexOf(this.selCons.toLowerCase()) >= 0);
        }

        if (!this.isDis) {
            if (this.selDis !== undefined && this.selDis !== '') {
                this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
                item.disolucion.toLowerCase().indexOf(this.selDis.toLowerCase()) >= 0);
            }
        } else {
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            item.disolucion.toLowerCase() === '');
        }

        if (this.selCat.length > 0) {
            const cats: number[] = [];
            const emps: number[] = [];

            this.selCat.map(x => { cats.push(x.categoriaID as number); });
            this.selEmpCat = Array.from(this.empCat.values()).filter((item: EmpCat) => cats.includes(item.categoriaID));
            this.selEmpCat.map(x => { emps.push(x.empresaID); });
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            emps.includes(item.empresaID as number));
        }

        if (this.selAct.length > 0) {
            const acts: number[] = [];
            const emps: number[] = [];

            this.selAct.map(x => { acts.push(x.actividadID  as number); });
            this.selEmpAct = Array.from(this.empAct.values()).filter((item: EmpAct) => acts.includes(item.actividadID));
            this.selEmpAct.map( x => { emps.push(x.empresaID); });
            this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
            emps.includes(item.empresaID as number));
        }

        this.numEmpresas = this.empresasSelect.length;
    }

    // Funcion controladora para la descarga de un ficher CSV
    downloadCSV(): void {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'TaxonomTIC',
            useBom: true,
            noDownload: false,
            headers: ['TÍTULO', 'CIF', 'CONTITUCIÓN', 'CORREO ELECTRÓNICO', 'DESCRIPCIÓN', 'DIRECCIÓN COMPLETA',
            'DISOLUCIÓN', 'FORMA JURÍDICA', 'LOCALIDAD', 'LATITUD', 'LONGITUD', 'NÚMERO DE TRABAJADORES',
            'PROVINCIA', 'PURA TIC', 'TELÉFONO', 'WEB'],
            nullToEmptyString: true,
        };

        const data: any[] = [];

        this.empresasSelect.forEach(element => {
            const e = {
                titulo : element.titulo,
                CIF : element.CIF,
                constitucion : element.constitucion,
                correoElectronico : element.correoElectronico,
                descripcion : element.descripcion,
                direccionCompleta : element.direccionCompleta,
                disolucion : element.disolucion,
                formaJuridica : element.formaJuridica,
                localidad : element.localidad,
                longitud : element.longitud, // Latitud y longitud están al revés por error inicial en la base de datos
                latitud : element.latitud,
                num_trabajadores : element.num_trabajadores,
                provincia : element.provincia,
                puraTIC : element.puraTIC,
                telefono : element.telefono,
                web : element.web
            };
            data.push(e);
        });

        // tslint:disable-next-line: no-unused-expression
        new AngularCsv(data, 'TAXO_Report', options);
    }

}

<div class="container">
	
	<div class="form-group">
        <h1>Política de Privacidad</h1>
		<h3>1. Protección de Datos de Carácter Personal</h3>
		<p>En cumplimiento de la Ley Orgánica 15/1999, de 13 de Diciembre, de Protección de Datos de Carácter Personal, la Fundación Computación y Tecnologías Avanzadas de Extremadura (en adelante COMPUTAEX) comunica a los usuarios del Sitio Web <a [routerLink]="['../']">olistic.cenits.es</a>, que los datos facilitados a través de nuestra página web mediante los correspondientes formularios y los e-mails recibidos, y que tienen la consideración de datos de carácter personal, serán incorporados a nuestros ficheros, con la finalidad de poder gestionar el servicio solicitado, contestar a su solicitud y/o tramitar su petición.</p>
		<p>Dichos ficheros se encuentran inscritos en la Agencia Española de Protección de Datos conforme a la legislación vigente y normativa de desarrollo.</p>
		<p>Los usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia, autenticidad, pertinencia y no excesividad de los Datos Personales proporcionados, y se comprometen a mantenerlos debidamente actualizados.</p>
		<p>En aquellos casos donde para realizar una solicitud sea necesario cumplimentar un formulario y hacer un "click" en el botón de enviar, la realización del mismo implicará necesariamente que ha sido informado y ha otorgado expresamente su consentimiento al contenido de la cláusula anexada a dicho formulario o aceptación de la política de privacidad.</p>

		<h3>2. Protección de Datos de Carácter PersonalDatos Facilitados por Terceros</h3>
		<p>En caso de que en la solicitud se incluyeran datos de carácter personal, por personas no titulares de los mismos, el usuario deberá, con carácter previo a su inclusión, informar a dichas personas de los extremos contenidos en los párrafos anteriores. COMPUTAEX se exime de cualquier responsabilidad por el incumplimiento de éste requisito.</p>

		<h3>3. Datos de Menores</h3>
		<p>No está autorizado facilitar datos de personas menores de catorce años de edad a través de este Sitio Web. COMPUTAEX se exime de cualquier responsabilidad por el incumplimiento de este requisito.</p>

		<h3>4. Comunicaciones Comerciales por Medios Electónicos</h3>
		<p>Las comunicaciones que se realicen por correo electrónico o por cualquier otro medio electrónico, serán las necesarias para gestionar su solicitud.</p>
		<p>No obstante, serán aquellas que hayan sido consentidas o autorizadas expresamente por los destinatarios de conformidad con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, salvo lo dispuesto por el artículo 21.2 de esta misma Ley, en la redacción dada por la Disposición Final Primera de la nueva Ley General de Telecomunicaciones.</p>

		<h3>5. Medidas de Seguridad</h3>
		<p>El responsable del fichero ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos, y ha instalado todos los medios y medidas técnicas a disposición según el estado de la tecnología para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados.</p>

		<h3>6. Deber de Secreto</h3>
		<p>El usuario cuenta con la confidencialidad y el deber de secreto de los empleados de COMPUTAEX y de todos aquellos que traten los datos en nombre y por cuenta del mismo. Sin perjuicio de lo anterior, el usuario es consciente de la posibilidad de que la seguridad de las comunicaciones a través de red no sea invulnerable.</p>

		<h3>7. Derechos de los Afectados</h3>
		<p>El usuario podrá ejercer los derechos de acceso, rectificación, cancelación y oposición de los datos personales según lo dispuesto en la Ley Orgánica de Protección de Datos de Carácter Personal (L.O. 15/1999) y el RD. 1720/2007, dirigiendo un escrito, adjuntando copia de su DNI o Pasaporte, a la siguiente dirección:</p>
		<p>Carretera Nacional 521, Km 41.8, CP 10071, Cáceres (España).</p>

		<h3>8. Información de las Empresas</h3>
		<p>La información disponible en el subdominio https://olistic.cenits.es sobre las empresas del sector TIC en Extremadura, no está sujeta a la LOPD como se indica en el Artículo 2 del REAL DECRETO 1720/2007, de 21 de diciembre:</p>
		<p>El presente reglamento será de aplicación a los datos de carácter personal registrados en soporte físico, que los haga susceptibles de tratamiento, y a toda modalidad de uso posterior de estos datos por los sectores público y privado.</p>
		<p>Este reglamento no será aplicable a los tratamientos de datos referidos a personas jurídicas, ni a los ficheros que se limiten a incorporar los datos de las personas físicas que presten sus servicios en aquéllas, consistentes únicamente en su nombre y apellidos, las funciones o puestos desempeñados, así como la dirección postal o electrónica, teléfono y número de fax profesionales.</p>
		<p>Asimismo, los datos relativos a empresarios individuales, cuando hagan referencia a ellos en su calidad de comerciantes, industriales o navieros, también se entenderán excluidos del régimen de aplicación de la protección de datos de carácter personal.</p>
		<p>Este reglamento no será de aplicación a los datos referidos a personas fallecidas. No obstante, las personas vinculadas al fallecido, por razones familiares o análogas, podrán dirigirse a los responsables de los ficheros o tratamientos que contengan datos de éste con la finalidad de notificar el óbito, aportando acreditación suficiente del mismo, y solicitar, cuando hubiere lugar a ello, la cancelación de los datos.</p>
		<p>Como indican los puntos 2 y 4 anteriores, dichos datos no están amparados por la LOPD. Sin embargo, desde COMPUTAEX se concede el uso de los derechos ARCO a aquellos empresarios y representantes de las formas jurídicas recogidas en el sitio https://olistic.cenits.es, de modo que, demostrando la titularidad de la persona jurídica representada será posible la modificación, eliminación o ampliación de los datos.</p>
		<p>Del mismo modo aquellos representantes de empresas o autónomos cuyas actividades puedan enmarcarse dentro del sector TIC y que no se encuentren actualmente en el directorio, podrán solicitar la inclusión de su empresa en el mismo. El equipo técnico de COMPUTAEX atenderá dicha petición y estimará si se considera oportuna dicha inclusión en un plazo no superior a una semana.</p>

		<h3>9. Contacte con Nosotros</h3>
		<p>Si tiene Ud. cualquier pregunta sobre nuestra Política de Privacidad, o si le gustaría hacer cualquier sugerencia o recomendación, por favor diríjase a nosotros a través de la siguiente dirección de correo electrónico: <a href="mailto:info@cenits.es">info@cenits.es</a></p>

	</div>
</div>

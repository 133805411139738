<div class="container">
	
	<div class="block">
        <h1>{{ titulo }}</h1>
		<p>{{ texto1 }}</p>
		<p>{{ texto2 }}</p>
    </div>
    <div >
		<img src="../../../../assets/img/cenits.jpg">
		<img src="../../../../assets/img/computaex.jpg">
    </div>
</div>

<div class="container">
	<div class="form-group">
        <h1>{{ titulo }}</h1>
		<div class="form-row">
			<div class="col-sm">
					<form>
						<p class="pgreen">Categorias TIC<br>
						<select multiple [(ngModel)]="selCat" name="selectedCat" (ngModelChange)="onChange()">
							<option *ngFor="let categoria of categorias" [value]="categoria">
							{{categoria.titulo}}</option></select></p>
					</form>
					<form>
						<p class="pgreen">Actividades TIC<br>
						<select multiple [(ngModel)]="selAct" name="selectedAct" (ngModelChange)="onChange()">
							<option *ngFor="let actividad of actividades" [value]="actividad">
							{{actividad.titulo}}</option></select></p>
					</form>
					<form>
						<p class="pgreen">Nombre o razón social<br>
						<input type="text" [(ngModel)]="selName" name="selectedName" (ngModelChange)="onChange()" title="Escribe el nombre de una empresa"></p>
					</form>
					<form>
						<p class="pgreen">Provincia<br>
							<select [(ngModel)]="selProv" name="selectedProv" (ngModelChange)="onChange()" title="Selecciona una provincia">
								<option selected value="undefined">---Seleccione una Provincia---</option>
								<option value="Badajoz">Badajoz</option>
								<option value="Cáceres">Cáceres</option>
							</select></p>

					</form>
					<form>
							<p class="pgreen">Localidad<br>
								<select [(ngModel)]="selLoc" name="selectedLoc" (ngModelChange)="onChange()" title="Selecciona una localidad" id="loc">
										<option selected value="undefined">---Seleccione una Localidad---</option>
										<option *ngFor="let localidad of localidades" [value]="localidad.loc">{{localidad.loc}}</option>
							</select></p>
					</form>
					<form>
						<p class="pgreen">Constitución<br>
						<input type="text" [(ngModel)]="selCons" name="selectedCons" (ngModelChange)="onChange()" title="Escribe el año de constitución de una empresa"></p>
					</form>
					<form>
                        <button class="btn btn-success" (click)="downloadCSV()">Descargar CSV</button>
                        <button class="btn btn-primary" (click)="reset()" >Reiniciar Selección</button>
                    </form>
                    <form>
                        <div id="popup" class="ol-popup" >
                            <h4>{{nombreEmpresa.titulo}}</h4>
                            <p class="popup-text">{{nombreEmpresa.direccion}}<br>
                                <a href="{{nombreEmpresa.web}}">{{nombreEmpresa.web}}</a><br>
                            {{nombreEmpresa.localidad}} {{nombreEmpresa.provincia}}<br></p>
                        </div>
                    </form>
			
				<div id="map" (click)="onClick($event)" class="map"></div>
				
			</div>
		</div>
	</div>
</div>

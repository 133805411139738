import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompetenciaTIC } from 'src/app/models/CompetenciaTIC';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetenciasTICService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getCompetenciasTIC(){
      return this.http.get(`${this.API_URI}/competenciasTIC`);
    }
    // tslint:disable-next-line: typedef
    getOneCompetenciaTIC(id: string){
      return this.http.get(`${this.API_URI}/competenciasTIC/${id}`);
    }
    // tslint:disable-next-line: typedef
    saveCompetenciaTIC(cat: CompetenciaTIC ){
      return this.http.post(`${this.API_URI}/competenciasTIC`, cat);
    }
    // tslint:disable-next-line: typedef
    deleteCompetenciaTIC(id: string){
      return this.http.delete(`${this.API_URI}/competenciasTIC/${id}`);
    }
    // tslint:disable-next-line: typedef
    updateCompetenciaTIC(id: string, cat: CompetenciaTIC){
      return this.http.put(`${this.API_URI}/competenciasTIC/${id}`, cat);
    }

}

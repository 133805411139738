import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpCat } from 'src/app/models/EmpCat';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { AutenticationService } from 'src/app/services/bbdd/autentication.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

@Component({
  selector: 'app-add-empresa',
  templateUrl: './add-empresa.component.html',
  styleUrls: ['./add-empresa.component.css']
})
export class AddEmpresaComponent implements OnInit {

// Vector que almacena los nombres de cada una de las forma juridcas
formas: string[] = ['Autónomo', 'Sociedad anónima', 'Sociedad de responsabilidad limitada',
'Sociedad colectiva', 'Sociedad comanditaria', 'Comunidad de bienes y herencias yacentes',
'Sociedad cooperativa', 'Asociación o fundación', 'Comunidad de propietarios en régimen de propiedad horizantal',
'Sociedad civil, con o sin personalidad jurídica', 'Entidad extranjera', 'Corporación local',
'Organismo público', 'Congrgación o institución religiosa', 'Órgano de la Administración del Estado y de las Comunidades Autónomas',
'Unión temporal de empresa', 'Establecimiento permanente de entidades no residentes en España', 'Otros'];

// Variable que almacena la información de la empresa seleccionada
empresa: EmpresaCompleta = new EmpresaCompleta();
// Variables temporales para cada uno de los campos de la empresa seleccionada
titulo = '';
CIF = '';
descripcion = '';
numTrabajadores = 0;
telefono = '';
web = '';
correoElectronico = '';
direccion = '';
direccionCompleta = '';
formaJuridica!: string ;
constitucion = '';
disolucion = '';
puraTIC!: string;
localidad  = '';
provincia!: string;
ubicacion = '';
latitud = 0;
longitud = 0;
currentID!: number;

// Vector que almacena el listado de empresas
empresas!: EmpresaCompleta[];
// Vector que almacena el conjunto de categorias TIC
categorias: CategoriaTIC[] = [];
// Vector que almacena el conjunto de actividades TIC
actividades: ActividadTIC[] = [];
// Categoria TIC seleccionada
selCat: CategoriaTIC[] = [];
// Actividad TIC seleccionada
selAct: ActividadTIC[] = [];

config = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
    maxHeight: 500
 };

 actividadesC: ActividadTIC[] = [];

/* ***************************************************************** */

constructor(private ea: EmpActService, private es: EmpresasService, private ec: EmpCatService,
            private aTICs: ActividadesTICService, private as: AutenticationService,
            private cTICs: CategoriasTICService, private router: Router) { }

ngOnInit(): void {
    window.scrollTo(0, 0);
    this.as.logged.subscribe(logged => {
        if (!logged) {
        this.router.navigateByUrl('/login');
        }
    });

    this.aTICs.getActividadesTIC().subscribe(
        res => {
        this.actividades = res as ActividadTIC[];
        },
        err => console.log(err)
    );

    this.cTICs.getCategoriasTIC().subscribe(
        res => {
        this.categorias = res as CategoriaTIC[];
        },
        err => console.log(err)
    );

}


// Controlador de acción al añadir una empresa a la base de datos
add(): void {

    this.empresa.CIF = this.CIF;
    this.empresa.constitucion = this.constitucion;
    this.empresa.correoElectronico = this.correoElectronico;
    this.empresa.descripcion = this.descripcion;
    this.empresa.direccionCompleta = this.direccionCompleta;
    this.empresa.disolucion = this.disolucion;
    this.empresa.formaJuridica = this.formaJuridica;
    this.empresa.localidad = this.localidad;
    this.empresa.longitud = this.longitud;
    this.empresa.latitud = this.latitud;
    this.empresa.num_trabajadores = this.numTrabajadores;
    this.empresa.provincia = this.provincia;
    this.empresa.puraTIC = this.puraTIC;
    this.empresa.telefono = this.telefono;
    this.empresa.titulo = this.titulo;
    this.empresa.ubicacion = this.direccion;
    this.empresa.web = this.web;


    this.es.saveEmpresasComp(this.empresa).subscribe(save => {
        this.es.getEmpresasComp().subscribe(
        get => {
            this.empresas = get as EmpresaCompleta[];
            this.currentID = this.empresas[this.empresas.length - 1].empresaID as number;
            const relCats: Set<EmpCat> = new Set<EmpCat>();
            this.selAct.forEach( act => {
                const relAct: EmpAct = new EmpAct();
                const relCat: EmpCat = new EmpCat();
                relAct.actividadID = act.actividadID as number;
                relAct.empresaID = this.currentID;
                relCat.categoriaID = act.categoriaID;
                relCat.empresaID = this.currentID;
                relCats.add(relCat);
                this.ea.saveRelation(relAct).subscribe();
            });
            relCats.forEach( rel => {
                this.ec.saveRelation(rel).subscribe();
            });
            this.resetForm();
            this.return();
            },
            err => console.log(err)
        );
    });
}

// Funcion de retorno al menu de administrador
return(): void {
    this.router.navigateByUrl('/admin');
}

// Funcion de reinicio del formulario de empresas
resetForm(): void {
    this.titulo = '';
    this.CIF = '';
    this.descripcion  = '';
    this.numTrabajadores = 0;
    this.telefono = '';
    this.web = '';
    this.correoElectronico = '';
    this.direccion = '';
    this.direccionCompleta = '';
    this.formaJuridica = '';
    this.constitucion = '';
    this.disolucion = '';
    this.localidad = '';
    this.ubicacion = '';
    this.latitud = 0;
    this.longitud = 0;
    while (this.selAct.length > 0) {
        this.selAct.pop();
    }
}

}

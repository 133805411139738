export class EmpresaCompleta {
    empresaID?: number;
    titulo = '';
    CIF = '';
    descripcion = '';
    // tslint:disable-next-line: variable-name
    num_trabajadores = 0;
    telefono = '';
    web = '';
    correoElectronico = '';
    direccion = '';
    direccionCompleta = '';
    formaJuridica = '';
    constitucion = '';
    disolucion = '';
    puraTIC = '';
    localidad = '';
    provincia = '';
    ubicacion = '';
    latitud = 0;
    longitud = 0;
}
import { Component, OnInit } from '@angular/core';

import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { HaversineService, GeoCoord } from 'ng2-haversine';
import Map from 'ol/Map';
import View from 'ol/View';
import {Tile, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {fromLonLat, transform} from 'ol/proj';
import {defaults as defaultInteractions} from 'ol/interaction.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Icon, Style} from 'ol/style';

import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpCat } from 'src/app/models/EmpCat';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { Localidad } from 'src/app/models/Localidad';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

@Component({
  selector: 'app-mapa-emp',
  templateUrl: './mapa-emp.component.html',
  styleUrls: ['./mapa-emp.component.css']
})
export class MapaEmpComponent implements OnInit {

    titulo = 'Mapa TIC';
    centerlat = 39.243319;
    centerlng = -6.288898;
    empresasAll: EmpresaCompleta[] = [];
    empresasSelect: EmpresaCompleta[] = [];
    categorias: CategoriaTIC[] = [];
    actividades: ActividadTIC[] = [];
    localidades: Localidad[] = [];
    localidadesExtremadura: Localidad[] = [];
    localidadesBadajoz: Localidad[] = [];
    localidadesCaceres: Localidad[] = [];
    empAct: EmpAct[] = [];
    empCat: EmpCat[] = [];

    selCat: CategoriaTIC[] = [];
    selAct: ActividadTIC[] = [];
    selLoc!: string;
    selCons!: string;
    selDis!: string;
    selPT!: string;
    selProv!: string;
    selName!: string;
    selEmpCat: EmpCat[] = [];
    selEmpAct: EmpAct[] = [];

    map!: Map;
    markers: Feature[] = [];
    sources!: VectorSource;
    layers!: VectorLayer;
    nombreEmpresa: EmpresaCompleta = new EmpresaCompleta();



    constructor(private hs: HaversineService, private es: EmpresasService, private cTICs: CategoriasTICService,
                private aTICs: ActividadesTICService, private ea: EmpActService, private ec: EmpCatService) {
    }

    async ngOnInit(): Promise<void> {
      window.scrollTo(0, 0);
      this.loadData();
      this.initMap();
    }

    loadData(): void {
      this.es.getEmpresasComp().subscribe(
        res => {
          this.empresasAll = res as EmpresaCompleta[];
          this.empresasSelect = this.empresasAll;
          this.loadMarkers();
        },
        err => console.log(err)
      );
      this.es.getLocalidades().subscribe(
        res => {
          this.localidades = res as Localidad[];
          this.localidades.shift();
          this.localidadesExtremadura = this.localidades;
        },
        err => console.log(err)
      );

      this.es.getLocalidadesBadajoz().subscribe(
        res => {
          this.localidadesBadajoz = res as Localidad[];
        },
        err => console.log(err)
      );

      this.es.getLocalidadesCaceres().subscribe(
        res => {
          this.localidadesCaceres = res as Localidad[];
        },
        err => console.log(err)
      );
      this.cTICs.getCategoriasTIC().subscribe(
        res => {
          this.categorias = res as CategoriaTIC[];
        },
        err => console.log(err)
      );

      this.aTICs.getActividadesTIC().subscribe(
        res => {
          this.actividades = res as ActividadTIC[];
        },
        err => console.log(err)
      );

      this.ea.getRelations().subscribe(
        res => {
          this.empAct =  res as EmpAct[];
        },
        err => console.log(err)
      );

      this.ec.getRelations().subscribe(
        res => {
          this.empCat = res as EmpCat[];
        },
        err => console.log(err)
      );
    }

    initMap(): void {
      this.map = new Map({
        target: 'map',
        layers: [
          new Tile({
            source: new OSM()
          })
        ],
        view: new View({
          center: fromLonLat([this.centerlng, this.centerlat]),
          zoom: 8
        }),
        controls: [],
        interactions: defaultInteractions()
      });

      const iconStyle = new Style({
        image: new Icon(/** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 46],
          anchorXUnits:  'fraction' as IconAnchorUnits,
          anchorYUnits: 'pixels' as IconAnchorUnits,
          opacity: 1,
          //src: 'http://www.myiconfinder.com/uploads/iconsets/32-32-6096188ce806c80cf30dca727fe7c237.png'
          src: '../../../../assets/img/marker2.png'
        }))
      });

      this.sources = new VectorSource({
        features: this.markers
      });

      this.layers = new VectorLayer({
        source: this.sources,
        style: iconStyle
      });
      this.map.addLayer(this.layers);
    }

    removeMarkers(): void {
      this.markers.forEach(element => {
        this.sources.removeFeature(element);
      });
      while (this.markers.length > 0) {
        this.markers.pop();
      }

    }

    addMarker(lon: number, lat: number, name: string): void {

      const iconFeature = new Feature({
        geometry: new Point(transform([lat, lon], 'EPSG:4326',
          'EPSG:3857')),
        name
      });

      this.markers.push(iconFeature);
      this.sources.addFeature(iconFeature);
      this.layers.setSource(this.sources);
    }

    loadMarkers(): void {
      this.removeMarkers();


      this.empresasSelect.forEach(element => {
        if (element.disolucion === undefined || element.disolucion === '') {
          this.addMarker(element.longitud, element.latitud, element.titulo);
          // longitud y latitud están al revés
        }
      });

      this.map.render();
    }

    haversine(a: EmpresaCompleta, b: any): number {

      const pointA: GeoCoord = {
        latitude: a.latitud,
        longitude: a.longitud
      };
      b = transform(b, 'EPSG:3857', 'EPSG:4326');

      const pointB: GeoCoord = {
        latitude: b[0],
        longitude: b[1]
      };

      const kmeters = this.hs.getDistanceInKilometers(pointA, pointB);

      return kmeters;
    }

    onClick(event: any): void {
      const coordinate = this.map.getEventCoordinate(event);

      let value;
      let min = 9999;
      let empresa: EmpresaCompleta;

      for (const element of this.markers) {
        empresa = Array.from(this.empresasAll.values()).filter((item: EmpresaCompleta) => item.titulo === element.get('name'))[0];
        value = this.haversine(empresa, coordinate);
        if (value < min) {
          this.nombreEmpresa = empresa;
          min = value;
        }
      }


    }



    onChange(): void {
      this.empresasSelect = this.empresasAll;

      if (this.selProv !== undefined && this.selProv !== 'undefined') {

        if (this.selProv === 'Badajoz') {
          const cac: Localidad = this.localidadesCaceres[0];

          if (this.localidades.includes(cac)) {  this.selLoc = 'undefined'; }
          this.localidades = this.localidadesBadajoz;
        }
        if (this.selProv === 'Cáceres') {
          const bad: Localidad = this.localidadesBadajoz[0];
          if (this.localidades.includes(bad)) { this.selLoc = 'undefined'; }
          this.localidades = this.localidadesCaceres;
        }

        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.provincia === this.selProv);
      } else {
        this.localidades = this.localidadesExtremadura;
      }

      if (this.selLoc !== undefined && this.selLoc !== 'undefined'){
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.localidad == this.selLoc);
      }

      if (this.selName !== undefined && this.selName !== '') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        item.titulo.toLowerCase().indexOf(this.selName.toLowerCase()) >= 0);
      }

      if (this.selPT !== undefined && this.selPT !== 'undefined') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        item.puraTIC.toLowerCase().indexOf(this.selPT.toLowerCase()) >= 0);
      }

      if (this.selCons !== undefined && this.selCons !== '') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        item.constitucion.toLowerCase().indexOf(this.selCons.toLowerCase()) >= 0);
      }

      if (this.selDis !== undefined && this.selDis !== '') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        item.disolucion.toLowerCase().indexOf(this.selDis.toLowerCase()) >= 0);
      }

      if (this.selCat.length > 0) {
        const cats: number[] = [];
        const emps: number[] = [];

        this.selCat.map(x => { cats.push(x.categoriaID as number); });
        this.selEmpCat = Array.from(this.empCat.values()).filter((item: EmpCat) => cats.includes(item.categoriaID));
        this.selEmpCat.map(x => { emps.push(x.empresaID); });
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => 
        emps.includes(item.empresaID as number));
      }

      if (this.selAct.length > 0){
        const acts: number[] = [];
        const emps: number[] = [];

        this.selAct.map(x => { acts.push(x.actividadID as number); });
        this.selEmpAct = Array.from(this.empAct.values()).filter((item: EmpAct) => acts.includes(item.actividadID));
        this.selEmpAct.map(x => { emps.push(x.empresaID); });
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => 
        emps.includes(item.empresaID as number));
      }

      this.loadMarkers();
     }

     downloadCSV(): void {

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'TaxonomTIC',
        useBom: true,
        noDownload: false,
        headers: ['TÍTULO', 'CIF', 'CONTITUCIÓN', 'CORREO ELECTRÓNICO', 'DESCRIPCIÓN' , 'DIRECCIÓN COMPLETA' , 'DISOLUCIÓN',
        'FORMA JURÍDICA', 'LOCALIDAD', 'LONGITUD', 'LATITUD', 'NÚMERO DE TRABAJADORES', 'PROVINCIA', 'PURA TIC', 'TELÉFONO', 'WEB'],
        nullToEmptyString: true,
      };

      const data: any[] = [];

      this.empresasSelect.forEach(element => {
          const e = {
            titulo : element.titulo,
            CIF : element.CIF,
            constitucion : element.constitucion,
            correoElectronico : element.correoElectronico,
            descripcion : element.descripcion,
            direccionCompleta : element.direccionCompleta,
            disolucion : element.disolucion,
            formaJuridica : element.formaJuridica,
            localidad : element.localidad,
            longitud : element.longitud,
            latitud : element.latitud,
            num_trabajadores : element.num_trabajadores,
            provincia : element.provincia,
            puraTIC : element.puraTIC,
            telefono : element.telefono,
            web : element.web
          };
          data.push(e);
      });

      // tslint:disable-next-line: no-unused-expression
      new AngularCsv(data, 'TAXO_Report', options);

   }

   reset(): void {
       this.loadData();
       this.selCat = [];
       this.selAct = [];
       this.selLoc = '---Seleccione una Localidad---';
       this.selCons = '';
       this.selDis = '';
       this.selPT = '';
       this.selProv = '---Seleccione una Provincia---';
       this.selName = '';
       this.selEmpCat = [];
       this.selEmpAct = [];
       this.nombreEmpresa = new EmpresaCompleta();
   }

}

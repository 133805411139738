<div class="container">
	<div class="block">
        <h1>{{ titulo }}</h1>
        <p style="text-align: justify;">{{ texto }}</p>		
        <p><img src="../../../../assets/img/perfilesProfesionales2020.jpg" alt="Perfiles Profesionales"></p>

            <div style="text-align: center;">
                <button id="buttonList" *ngFor="let perfil of perfiles" (click)="onClickPerfil(perfil.perfilID!)">{{perfil.titulo}}</button>
            </div>
            <p *ngIf="perfilDescripcion" id="perf_descripcion">{{perfilDescripcion}}</p>
            <div *ngIf="perfilDescripcion" style="text-align: center;">
                <button *ngFor="let competencia of selCompetencias" (click)="onClickCompetencia(competencia.descripcion!)">{{competencia.titulo}}</button>
            </div>
            <p *ngIf="competenciaDescripcion" id="com_descripcion">{{competenciaDescripcion}}</p>
	</div>
	
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PerfilCompetencia } from 'src/app/models/PerfilCompetencia';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerfilCompetenciaService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getPerfilesCompetencia(){
      return this.http.get(`${this.API_URI}/perfilCompetencia`);
    }
    // tslint:disable-next-line: typedef
    getOnePerfilCompetencia(id: string){
      return this.http.get(`${this.API_URI}/perfilCompetencia/${id}`);
    }
    // tslint:disable-next-line: typedef
    savePerfilCompetencia(cat: PerfilCompetencia ){
      return this.http.post(`${this.API_URI}/perfilCompetencia`, cat);
    }
    // tslint:disable-next-line: typedef
    deletePerfilCompetencia(id: string){
      return this.http.delete(`${this.API_URI}/perfilCompetencia/${id}`);
    }
    // tslint:disable-next-line: typedef
    updatePerfilCompetencia(id: string, cat: PerfilCompetencia){
      return this.http.put(`${this.API_URI}/perfilCompetencia/${id}`, cat);
    }
}

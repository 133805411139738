import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { AnosValue } from 'src/app/models/AnosValue';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpCat } from 'src/app/models/EmpCat';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { Localidad } from 'src/app/models/Localidad';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

@Component({
  selector: 'app-porcentaje-tic',
  templateUrl: './porcentaje-tic.component.html',
  styleUrls: ['./porcentaje-tic.component.css']
})
export class PorcentajeTICComponent implements OnInit {

    titulo = 'Porcentaje de Actividades TIC';

    numEmpresas = 0;
    totalEmpresas = 0;
    empresasAll: EmpresaCompleta[] = [];
    empresasSelect: EmpresaCompleta[] = [];
    constituidas: AnosValue[] = [];
    disueltas: AnosValue[] = [];
    localidades: Localidad[] = [];
    localidadesExtremadura: Localidad[] = [];
    localidadesBadajoz: Localidad[] = [];
    localidadesCaceres: Localidad[] = [];
    empAct: EmpAct[] = [];
    empCat: EmpCat[] = [];
    actividades: ActividadTIC[] = [];
    categorias: CategoriaTIC[] = [];

    formas: string[] = ['Autónomo', 'Sociedad anónima', 'Sociedad de responsabilidad limitada',
                          'Sociedad colectiva', 'Sociedad comanditaria', 'Comunidad de bienes y herencias yacentes',
                          'Sociedad cooperativa', 'Asociación o fundación', 'Comunidad de propietarios en régimen de propiedad horizontal',
                          'Sociedad civil, con o sin personalidad jurídica', 'Entidad extranjera', 'Corporación local',
                          'Organismo público', 'Congregación o institución religiosa',
                          'Órgano de la Administración del Estado y de las Comunidades Autónomas',
                          'Unión Temporal de Empresa', 'Establecimiento permanente de entidades no residentes en España', 'Otros'];
    selFor: string[] = [];
    selLoc!: string;
    selProv!: string;
    selAct: ActividadTIC[] = [];
    selActTemp: ActividadTIC[] = [];
    selCategoria: CategoriaTIC[] = [];
    selEmpAct: EmpAct[] = [];
    selEmpCat: EmpCat[] = [];
    colors: any[] = [];

    doughnutChartLabels1: string[] = [];
    doughnutChartData1 = [];
    doughnutChartType1: ChartType = 'doughnut';
    doughnutChartOptions1 = {
      legend: {
         display: false
      },
      tooltips: {
         enabled: true
      }
    };

    doughnutColors1 = [
      {
        backgroundColor: this.colors
      }
    ];

    doughnutChartLabels2: string[] = [];
    doughnutChartData2 = [];
    doughnutChartType2: ChartType = 'doughnut';
    doughnutChartOptions2 = {
      legend: {
         display: false
      },
      tooltips: {
         enabled: true
      }
    };
    doughnutColors2 = [
      {
        backgroundColor: this.colors
      }
    ];

    constructor(private es: EmpresasService, private cTICs: CategoriasTICService, private aTICs: ActividadesTICService,
                private ea: EmpActService, private ec: EmpCatService) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.loadData();
      this.delay(500);
      this.formas.sort((one, two) => (one > two ? 1 : -1));
      this.updateDataCategoria();
      this.updateDataActividad();
      this.onChange();

    }

    loadData(): void {
      this.es.getEmpresasComp().subscribe(
        res => {
          this.empresasAll =  res as EmpresaCompleta[];
          this.empresasSelect = this.empresasAll;
          this.totalEmpresas = this.empresasAll.length;
          this.numEmpresas = this.empresasSelect.length;
         },
        err => console.log(err)
      );

      this.es.getLocalidades().subscribe(
        res => {
          this.localidades =  res as Localidad[];
          this.localidades.shift();
          this.localidadesExtremadura = this.localidades;
        },
        err => console.log(err)
      );

      this.es.getLocalidadesBadajoz().subscribe(
        res => {
          this.localidadesBadajoz =  res as Localidad[];
        },
        err => console.log(err)
      );

      this.es.getLocalidadesCaceres().subscribe(
        res => {
          this.localidadesCaceres =  res as Localidad[];
        },
        err => console.log(err)
      );

      this.cTICs.getCategoriasTIC().subscribe(
        res => {
          this.categorias =  res as CategoriaTIC[];
          this.categorias.sort((one, two) => (one.titulo > two.titulo ? 1 : -1));
          this.selCategoria = this.categorias;
        },
        err => console.log(err)
      );

      this.aTICs.getActividadesTIC().subscribe(
        res => {
          this.actividades =  res as ActividadTIC[];
        },
        err => console.log(err)
      );

      this.ea.getRelations().subscribe(
        res => {
          this.empAct =  res as EmpAct[];
          this.selEmpAct = this.empAct;
        },
        err => console.log(err)
      );

      this.ec.getRelations().subscribe(
        res => {
          this.empCat =  res as EmpCat[];
          this.selEmpCat = this.empCat;
        },
        err => console.log(err)
      );
    }

    getRandomColor(): string {
      const color = Math.floor(0x1000000 * Math.random()).toString(16);
      return '#' + ('000000' + color).slice(-6);
    }

    async updateDataCategoria(): Promise<void> {

      await this.delay(300);
      while (this.colors.length > 0) { this.colors.pop(); }
      while (this.doughnutChartLabels1.length > 0) { this.doughnutChartLabels1.pop(); }
      while (this.doughnutChartData1.length > 0) { this.doughnutChartData1.pop(); }


      if (this.selCategoria.length === 0) {this.selCategoria = this.categorias; }

      const idc: number[] = [];
      this.selCategoria.map(x => {idc.push(x.categoriaID as number); });
      this.selAct = Array.from(this.actividades.values()).filter((item: ActividadTIC) => idc.includes(item.categoriaID));

      const ida: number[] = [];
      this.selAct.map(x => {ida.push(x.actividadID as number); });
      this.selEmpAct = Array.from(this.empAct.values()).filter((item: EmpAct) => ida.includes(item.actividadID));

      const ide: number[] = [];
      this.empresasSelect.map(x => {ide.push(x.empresaID as number); });

      const temp: string[] = [];
      this.selCategoria.map(x => { temp.push(x.titulo); });
      this.doughnutChartLabels1 = temp;
      this.doughnutChartLabels1.sort((one, two) => (one > two ? 1 : -1));
      this.doughnutChartLabels1.forEach(element => {
        this.colors.push(this.getRandomColor());
      });

      this.selEmpCat = Array.from(this.empCat.values()).filter((item: EmpCat) => ide.includes(item.empresaID));
      this.selCategoria.map(x => {
        this.doughnutChartData1.push(Array.from(this.selEmpCat.values()).filter((item: EmpCat) =>
        item.categoriaID === x.categoriaID).length as never);
      });

      this.numEmpresas = this.empresasSelect.length;
    }

    async updateDataActividad(): Promise<void> {
      await this.delay(300);

      while (this.selAct.length > 0) { this.selAct.pop(); }
      while (this.doughnutChartLabels2.length > 0) { this.doughnutChartLabels2.pop(); }
      while (this.doughnutChartData2.length > 0) { this.doughnutChartData2.pop(); }

      const ids: number[] = [];
      this.empresasSelect.map(x => {ids.push(x.empresaID as number); });

      this.selActTemp = this.actividades;

      this.selCategoria.map(element => {

        this.selActTemp = Array.from(this.selActTemp.values()).filter((item: ActividadTIC) => item.categoriaID === element.categoriaID);
        this.selActTemp.map(x => { if (!this.selAct.includes(x)) { this.selAct.push(x); }});

        this.selActTemp = this.actividades;
      });

      this.selEmpAct = Array.from(this.selEmpAct.values()).filter((item: EmpAct) => ids.includes(item.empresaID));

      const temp: string[] = [];
      this.selAct.map(x => { temp.push(x.titulo); });
      this.doughnutChartLabels2 = temp;

      this.doughnutChartLabels2.sort((one, two) => (one > two ? 1 : -1));

      this.doughnutChartLabels2.forEach(element => {
        this.colors.push(this.getRandomColor());
      });

      this.selAct.forEach(element => {
        this.doughnutChartData2.push(Array.from(this.selEmpAct.values()).filter((item: EmpAct) =>
        item.actividadID === element.actividadID).length as never);
      });

    }

    onChange(): void {

      console.log('onChange()');
      this.empresasSelect = this.empresasAll;

      if (this.selProv !== undefined && this.selProv !== 'undefined') {

        if (this.selProv === 'Badajoz') {
          const cac: Localidad = this.localidadesCaceres[0];

          if (this.localidades.includes(cac)) {  this.selLoc = this.localidadesBadajoz[0].loc; }
          this.localidades = this.localidadesBadajoz;
        }
        if (this.selProv === 'Caceres') {
          const bad: Localidad = this.localidadesBadajoz[0];

          if (this.localidades.includes(bad)) {  this.selLoc = this.localidadesCaceres[0].loc; }
          this.localidades = this.localidadesCaceres;
        }

        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.provincia === this.selProv);

      } else {
        this.localidades = this.localidadesExtremadura;
      }

      if (this.selLoc !== undefined && this.selLoc !== 'undefined') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.localidad === this.selLoc);
      }

      if (this.selFor.length > 0) {
        this.empresasSelect =  Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        this.selFor.includes(item.formaJuridica));
      }

      if (this.selCategoria.length > 0) {
          this.updateDataCategoria();
          this.updateDataActividad();
      }
    }


    // tslint:disable-next-line: typedef
    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }


}

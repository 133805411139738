import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpCat } from 'src/app/models/EmpCat';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

import Map from 'ol/Map';
import View from 'ol/View';
import {Tile, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {fromLonLat, transform} from 'ol/proj';
import {defaults as defaultInteractions} from 'ol/interaction.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Icon, Style} from 'ol/style';


import { AutenticationService } from 'src/app/services/bbdd/autentication.service';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { MapOptions } from 'ol/PluggableMap';

@Component({
  selector: 'app-empresa-site',
  templateUrl: './empresa-site.component.html',
  styleUrls: ['./empresa-site.component.css']
})
export class EmpresaSiteComponent implements OnInit {

    login!: boolean;
    value!: string;

    empresa: EmpresaCompleta = new EmpresaCompleta();
    titulo = '';
    CIF = '';
    descripcion  = '';
    numTrabajadores = 0;
    telefono = '';
    web = '';
    correoElectronico = '';
    direccion = '';
    direccionCompleta = '';
    formaJuridica = '';
    constitucion = '';
    disolucion = '';
    puraTIC  = '';
    localidad  = '';
    provincia  = '';
    ubicacion  = '';
    latitud  = 0;
    longitud = 0;
    empresaID!: number;


    empAct: EmpAct[] = [];
    empCat: EmpCat[] = [];
    selEmpAct: EmpAct[] = [];
    selEmpCat: EmpCat[] = [];
    actividades: ActividadTIC[] = [];
    selActividades: ActividadTIC[] = [];
    categorias: CategoriaTIC[] = [];
    selCategorias: CategoriaTIC[] = [];


    map!: Map;
    markers: Feature[] = [];
    sources!: VectorSource;
    layers!: VectorLayer;
    centerlat = 39.243319;
    centerlng = -6.288898;

    constructor(private es: EmpresasService,  private as: AutenticationService, private route: ActivatedRoute,
                private ea: EmpActService, private ec: EmpCatService, private aTICs: ActividadesTICService,
                private cTICs: CategoriasTICService) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.loadData();
    }

    loadData(): void {

      this.es.getOneEmpresasCompbyTit(this.route.snapshot.paramMap.get('titulo') as string).subscribe(
        res => {
          this.empresa = res as EmpresaCompleta;
          this.titulo = this.empresa.titulo;
          this.latitud = this.empresa.latitud;
          this.longitud = this.empresa.longitud;
          this.CIF = this.empresa.CIF;
          this.telefono = this.empresa.telefono;
          this.numTrabajadores = this.empresa.num_trabajadores;
          this.web = this.empresa.web;
          this.correoElectronico = this.empresa.correoElectronico;
          this.descripcion = this.empresa.descripcion;
          this.formaJuridica = this.empresa.formaJuridica;
          this.constitucion = this.empresa.constitucion.toString();

          this.loadData2();

          if (this.constitucion === '') {
            this.constitucion = 'Fecha desconocida';
          }
          this.disolucion = this.empresa.disolucion.toString();
          if (this.disolucion === '') {
            this.disolucion = 'En activo';
          }
          this.provincia = this.empresa.provincia;
          this.localidad = this.empresa.localidad;
          this.direccionCompleta = this.empresa.direccionCompleta;
          this.initMap();
        },
        err => console.log(err)
      );

      this.as.logged.subscribe(logged => {
          this.login = logged;
          if (this.login) {
              this.value = 'Usuario: ' + this.as.getUser().nombre;
          } else {
              this.value = 'Administrador';
          }
      });
  }

  loadData2(): void {

      this.cTICs.getCategoriasTIC().subscribe(
          res => {
            this.categorias = res as CategoriaTIC[];
            this.selCategorias = this.categorias;
          },
          err => console.log(err)
        );

      this.aTICs.getActividadesTIC().subscribe(
          res => {
            this.actividades = res as ActividadTIC[];
            this.selActividades = this.actividades;
          },
          err => console.log(err)
        );

      this.ea.getRelations().subscribe(
          res => {
            this.empAct = res as EmpAct[];
            this.selEmpAct = Array.from(this.empAct.values()).filter((item: EmpAct) => item.empresaID === this.empresa.empresaID);


            const acts: number[] = [];
            this.selEmpAct.map(x => {acts.push(x.actividadID); });

            this.selActividades = Array.from(this.selActividades.values()).filter((item: ActividadTIC) =>
            acts.includes(item.actividadID as number));

            const cats: number[] = [];
            this.selActividades.map(x => {cats.push(x.categoriaID); });
            this.selCategorias  = Array.from(this.selCategorias.values()).filter((item: CategoriaTIC) =>
            cats.includes(item.categoriaID as number));
          },
          err => console.log(err)
        );

  }

  // tslint:disable-next-line: typedef
  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }

  initMap(): void {

      this.removeMarkers();

      this.map = new Map({
        target: 'map',
        layers: [
          new Tile({
            source: new OSM()
          })
        ],
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true,
        view: new View({
          center: fromLonLat([this.latitud, this.longitud]),
          zoom: 12
        }),
        controls: [],
        interactions: defaultInteractions()
      } as MapOptions);

      const iconStyle = new Style({
        // tslint:disable-next-line: no-redundant-jsdoc
        image: new Icon(/** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 46],
          anchorXUnits:  'fraction' as IconAnchorUnits,
          anchorYUnits: 'pixels' as IconAnchorUnits,
          opacity: 1,
          //src: 'http://www.myiconfinder.com/uploads/iconsets/32-32-6096188ce806c80cf30dca727fe7c237.png'
          src: '../../../../assets/img/marker2.png'
        }))
      });

      this.sources = new VectorSource({
        features: this.markers
      });

      this.layers = new VectorLayer({
        source: this.sources,
        style: iconStyle
      });

      this.map.addLayer(this.layers);

      this.addMarker(this.longitud, this.latitud, this.titulo);
    }

    addMarker(lon: number, lat: number, nam: string): void {
      // console.log("addMarker", _name);
      const iconFeature = new Feature({
        geometry: new Point(transform([lat, lon], 'EPSG:4326',
          'EPSG:3857')),
        name: nam
      });

      this.markers.push(iconFeature);
      this.sources.addFeature(iconFeature);
      this.layers.setSource(this.sources);
    }

    removeMarkers(): void {
      this.markers.forEach(element => {
        this.sources.removeFeature(element);
      });
      while (this.markers.length > 0) {
        this.markers.pop();
      }

    }

}

<div class="container">
   
  
    <div class="form-group">
        <h1>Encuesta Profesional TIC</h1>
        <p>El proyecto TaxonomTIC pretende caracterizar los perfiles profesionales TIC en Extremadura.
        Con tal fin, se ha elaborado el siguiente formulario, basado en el e-CF (European e-Competence Framework),
        de modo que sea posible seleccionar cada uno de los perfiles profesionales TIC y valorar las competencias
        electrónicas asociadas al mismo. Nos gustaría que, en caso de que usted se considere un profesional TIC,
        dedique unos minutos a realizar la encuesta.</p>
        <p>Toda la información facilitada en esta encuesta será anónima y será utilizada exclusivamente para elaborar 
        estadísticas representativas de la situación actual de las profesiones TIC de la región.</p>
        <p>Muchas gracias por su colaboración.</p>
    </div>
  
    <br>
  
    <div *ngIf="step1" class="container">
  
        <div class="form-row">
  
            <div class="col-sm">  
                <p class="pgreen">  
                    <label>Sexo<span style="color:red">*</span>: </label> 
                    <select [(ngModel)]="selSex" (ngModelChange)="onChange()" title="Obligatorio">
                    <option value="" selected disabled>---Seleccione una opción---</option>
                    <option value="hombre">Hombre</option>
                    <option value="mujer">Mujer</option>
                    <option value="otro">Otro</option>
                    </select>
                </p>
            </div>
  
            <div class="col-sm"> 
                <p class="pgreen">         
                    <label>Edad<span style="color:red">*</span>: </label> 
                    <select [(ngModel)]="selEdad" (ngModelChange)="onChange()" title="Obligatorio">
                    <option value="" selected disabled >---Seleccione una opción---</option>
                    <option value="Menor de 35 años">Menor de 35 años</option>
                    <option value="Entre 35 y 45 años">Entre 35 y 45 años</option>
                    <option value="Mayor de 45 años">Mayor de 45 años</option>
                </select>
                </p>      
            </div>  
        </div>
  
        <div class="form-row">	
            <div class="col-sm">
                <p class="pgreen">        
                    <label>Estudios<span style="color:red">*</span>: </label> 
                    <select [(ngModel)]="selEstudios" (ngModelChange)="onChange()" title="Obligatorio">
                    <option value="" selected disabled >---Seleccione una opción---</option>
                    <option value="Sin Estudios">Sin Estudios</option>
                    <option value="Graduado Escolar">Graduado Escolar</option>
                    <option value="Bachillerato / FP">Bachillerato / FP</option>
                    <option value="Titulado de Grado">Titulado de Grado</option>
                    <option value="Titulado de Máster">Titulado de Máster</option>
                    <option value="Titulado de Tercer Ciclo">Titulado de Tercer Ciclo</option>
                    </select>
                </p>
            </div>
        </div>
  
        <div *ngIf="startVisible" class="form-row">  
            <div class="col-sm">
                <form>
                    <button class="btn btn-primary" type="button" (click)="next()">Comenzar Encuesta</button>
                </form>
            </div>  
        </div>

    </div>
  
  
    <div *ngIf="step2" class="block">
  
      <div class="form-row">
  
        <div class="col-sm">  
          <p class="pgreen">  
            <label>Perfil Profesional Actual<span style="color:red">*</span>: </label> 
            <select [(ngModel)]="selPerfil" (ngModelChange)="onChange()" title="Obligatorio">
            <option value="" selected disabled >---Seleccione una opción---</option>
            <option *ngFor="let perfil of perfiles" [value]="perfil.titulo">{{perfil.titulo}}</option>
            </select>
          </p>
        </div>
      </div>
  
      <div class="form-row">
  
        <div class="col-sm">  
          <p class="pgreen">  
            <label>Experiencia en el Perfil Seleccionado<span style="color:red">*</span>: </label> 
            <select [(ngModel)]="selExperiencia" (ngModelChange)="onChange()" title="Obligatorio">
            <option value="" selected disabled >---Seleccione una opción---</option>
            <option value="Menor de 2 años">Menor de 2 años</option>
            <option value="Entre 2 y 10 años">Entre 2 y 10 años</option>
            <option value="Más de 10 años">Más de 10 años</option>
            </select>
          </p>
        </div>
  
        <div class="col-sm"> 
          <p class="pgreen">
            <label>Sueldo Bruto Anual (€)<span style="color:red">*</span>: </label> 
            <input type="number" [(ngModel)]="selSueldo" (ngModelChange)="onChange()" title="Cantidad en Euros">
          </p>     
        </div>
  
      </div>
  
      <div *ngIf="continue23" class="form-row">
        <div class="col-sm">
          <form>
            <button  class="btn btn-primary"  type="button" (click)="next()">Continuar</button>
          </form>
        </div>
      </div>
    </div>
  
  
  
    <div *ngIf="step3" class="container">
  
      <div class="form-row">
          <div class="descripcion"> 
            <label style="font-weight: bold; font-size: 12px" >Valore su experiencia de 1 a 5 en las distintas competencias 
              siendo 1 el nivel más bajo y 5 el nivel más alto.</label>
          </div>
        <div *ngFor="let question of test"class="column3">  
            <p class="pgreen">
              <label>{{question.titulo}}: </label> 
            </p>
            <form class="radio">
                <input [(ngModel)]="question.value" name="1" (ngModelChange)="onChangeTest()" type="radio" value="1" />1
                <input [(ngModel)]="question.value" name="2" (ngModelChange)="onChangeTest()" type="radio" value="2" />2
                <input [(ngModel)]="question.value" name="3" (ngModelChange)="onChangeTest()" type="radio" value="3" />3
                <input [(ngModel)]="question.value" name="4" (ngModelChange)="onChangeTest()" type="radio" value="4" />4
                <input [(ngModel)]="question.value" name="5" (ngModelChange)="onChangeTest()" type="radio" value="5" />5
            </form>
            <div class="descripcion">
              {{question.descripcion}}
            </div>
        </div>
  
        <div *ngIf="finalizar" class="form-row">
          <div class="col-sm" style="margin-top: 20px">
            <form>
              <button  class="btn btn-primary" type="button" (click)="next()">Finalizar</button>
            </form>
          </div>
        </div>  
      </div>
    </div>
  </div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // root_address : 'http://localhost:4200',
    // server_address: 'http://localhost:3000/api',
    root_address: 'https://olistic.cenits.es/olistic', //'http://olistic.cenits.es/olistic/'
    server_address: 'https://olistic.cenits.es:3000/api', ///'https://10.128.3.111:3000/api',
    assets_addres: './assets'
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriasTICService {

  API_URI = environment.server_address;

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  getCategoriasTIC(){
    return this.http.get(`${this.API_URI}/categoriasTIC`);
  }
  // tslint:disable-next-line: typedef
  getOneCategoriaTIc(id: string){
    return this.http.get(`${this.API_URI}/categoriasTIC/${id}`);
  }
  // tslint:disable-next-line: typedef
  saveCategoriaTIC(cat: CategoriaTIC ){
    return this.http.post(`${this.API_URI}/categoriasTIC`, cat);
  }
  // tslint:disable-next-line: typedef
  deleteCategoriaTIC(id: string){
    return this.http.delete(`${this.API_URI}/categoriasTIC/${id}`);
  }
  // tslint:disable-next-line: typedef
  updateCategoriaTIC(id: string, cat: CategoriaTIC){
    return this.http.put(`${this.API_URI}/categoriasTIC/${id}`, cat);
  }

}

<div class="container">
	<div class="form-group">
        <h1>Nueva Empresa</h1>
        <p class="pgreen">Título<br>
            <input type="text"  [(ngModel)]="titulo" name="newTitulo" placeholder="Título"></p>
        <div class="form-row">
            <div class="col-sm">
                    <p class="pgreen">CIF<br>
                        <input  type="text"  [(ngModel)]="CIF" name="newCif" placeholder="CIF"></p>
                    <p class="pgreen">Número de Trabajadores<br>
                        <input  type="number" [(ngModel)]="numTrabajadores" name="newNum" placeholder="Número de trabajadores"></p>
                    <p class="pgreen">Teléfono<br>
                        <input  type="text" [(ngModel)]="telefono" name="newTelefono" placeholder="Teléfono"></p>
            </div>
            <div class="col-sm">
                    <p class="pgreen">Web<br>
                        <input  type="text"  [(ngModel)]="web" name="newWeb" placeholder="Web"></p>
                    <p class="pgreen">Correo Electónico<br>
                        <input  type="text" [(ngModel)]="correoElectronico" name="newCorreo" placeholder="Correo Electónico"></p>
                    <p class="pgreen">Dirección<br>
                        <input  type="text" [(ngModel)]="direccion" name="newDireccion" placeholder="Dirección"></p>
                    
            </div>
        </div>
        <div class="form-row">
            <div class="col-sm">
                    <p class="pgreen">Descripción<br>
                        <textarea rows="16" [(ngModel)]="descripcion" name="newDescripcion" placeholder="Descripción"></textarea></p>
                    <p class="pgreen">Categorias TIC<br>
                        <select multiple name="selectedCat">
                            <option disabled *ngFor="let categoria of categorias" >
                            {{categoria.categoriaID}} - {{categoria.titulo}}</option></select></p> 
            </div>
            <div class="col-sm">
                <p class="pgreen">Dirección Completa<br>
                    <textarea rows="16" [(ngModel)]="direccionCompleta" name="newDirComp" placeholder="Dirección Completa"></textarea></p>
                <p class="pgreen">Actividades TIC<br>
                    <select multiple [(ngModel)]="selAct" name="selectedAct">
                        <option *ngFor="let actividad of actividades" [value]="actividad">
                        {{actividad.categoriaID}} - {{actividad.titulo}}</option></select></p> 
            </div>
        </div>


        <div class="form-row">
                <div class="col-sm">
                    <p class="pgreen">Provincia<br> 
                    <select [(ngModel)]="provincia" name="selectedProv" id="selectProv" title="Selecciona una provincia">
                            <option selected disabled value="undefined">---Seleccione una Provincia---</option>
                            <option value="Badajoz">Badajoz</option>
                            <option value="Cáceres">Cáceres</option>
                    </select></p>
                    <p class="pgreen">Latitud<br>
                        <input  type="text"  [(ngModel)]="longitud" name="newLon" placeholder="Longitud"></p> 
                    
                    <p class="pgreen">Pura TIC<br>
                        <select [(ngModel)]="puraTIC" name="selectedPT" id="selectPT"  title="Selecciona una condición">
                            <option selected disabled value="undefined">---¿Es Pura TIC?---</option>
                            <option value="SI">Si</option>
                            <option value="NO">No</option>
                        </select></p>
                    <p class="pgreen">Contitución de la Empresa<br>
                        <input  type="text"  [(ngModel)]="constitucion" name="newCons" placeholder="Año de Constitución"></p>   
    
                </div>
                <div class="col-sm">
                        <p class="pgreen">Localidad<br>
                            <input  type="text"  [(ngModel)]="localidad" name="newLoc" placeholder="Localidad"></p> 
                        <p class="pgreen">Longitud<br> 
                            <input  type="text"  [(ngModel)]="latitud" name="newLat" placeholder="Latitud"></p>
                        
                        <p class="pgreen">Forma Jurídica<br>
                            <select [(ngModel)]="formaJuridica" name="selectedFJ" id="selectFJ"  title="Selecciona una forma jurídica">
                                    <option selected disabled value="undefined">---Seleccione una Forma Jurídica---</option>
                                    <option *ngFor="let forma of formas" [value]="forma">
                                    {{forma}}</option>
                            </select></p>
                        <p class="pgreen">Disolución de la Empresa<br>
                            <input  type="text"  [(ngModel)]="disolucion" name="newDiso" placeholder="Año de Disolución"></p>  
                </div>

        </div> 
   
        <br>
        <br>
        <div class="line">
            <button class="btn btn-success"  (click)="add()">Añadir</button>
            <button class="btn btn-danger" (click)="return()">Volver</button>
        </div>
        <br>
        <br>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';

@Component({
  selector: 'app-actividades-tic',
  templateUrl: './actividades-tic.component.html',
  styleUrls: ['./actividades-tic.component.css']
})
export class ActividadesTICComponent implements OnInit {

  // Vector que almacena el conjunto de categorias TIC
  categorias: CategoriaTIC[] = [];
  // Variable que almacena la descripción de la categoria TIC seleccionada
  categoriaDescripcion = '';
  // Vector que almacena el conjunto de actividades TIC
  actividades: ActividadTIC[] = [];
  // Variable que almacena la descripción de la actividad TIC seleccionada
  actividadDescripcion = '';

  /* ***************************************************************** */

  constructor(private cTICs: CategoriasTICService, private aTICs: ActividadesTICService) {
  }

  ngOnInit(): void {
      window.scrollTo(0, 0);
      console.log(document.location.href);
      this.cTICs.getCategoriasTIC().subscribe(
          res => {
              this.categorias = res as CategoriaTIC[];
          },
          err => console.log(err)
      );
  }

  // Función que controla cuando se hace click en algun botón de categoria TIC.
  // El controlador de los botones de actividad TIC se realiza en el código HTML.
  onClickCategoria(id: number): void {
      this.categoriaDescripcion = this.categorias[id - 1].descripcion;
      this.actividadDescripcion = '';
      this.aTICs.getActividadesTICbyCategoria(id.toString()).subscribe(
          res => {
              this.actividades = res as ActividadTIC[];
          },
          err => console.log(err)
      );
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompetenciaTIC } from 'src/app/models/CompetenciaTIC';
import { Correo } from 'src/app/models/Correo';
import { Counter } from 'src/app/models/Counter';
import { Encuestas } from 'src/app/models/Encuestas';
import { PerfilCompetencia } from 'src/app/models/PerfilCompetencia';
import { PerfilesTIC } from 'src/app/models/PerfilesTIC';
import { Question } from 'src/app/models/Question';
import { Submission } from 'src/app/models/Submission';
import { CompetenciasTICService } from 'src/app/services/bbdd/competencias-tic.service';
import { EncuestasService } from 'src/app/services/bbdd/encuestas.service';
import { PerfilCompetenciaService } from 'src/app/services/bbdd/perfil-competencia.service';
import { PerfilesTICService } from 'src/app/services/bbdd/perfiles-tic.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.css']
})
export class EncuestaComponent implements OnInit {

    startVisible = false;
    continue23 = false;
    finalizar = false;

    step1 = true;
    step2 = false;
    step3 = false;

    perfiles: PerfilesTIC[] = [];
    competencias: CompetenciaTIC[] = [];
    perCom: PerfilCompetencia[] = [];
    test: Question[] = [];
    submissions: Counter[] = [];

    selSex = '';
    selEstudios = '';
    selEdad = '';
    selPerfil = '';
    selExperiencia = '';
    selSueldo = 0;
    date = (new Date()).getFullYear().toString();

    idPerfil = -1;
    selPerCom: PerfilCompetencia[] = [];
    selCompetencias: CompetenciaTIC[] = [];

    submitCounter: Counter = new Counter();

    constructor(private ps: PerfilesTICService, private cs: CompetenciasTICService, private pcs: PerfilCompetenciaService,
                private router: Router, private es: EncuestasService, private ns: NotifierService) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.ps.getPerfilesTIC().subscribe(
        res => {
          this.perfiles =  res as PerfilesTIC[];
        },
        err => console.log(err)
      );

      this.cs.getCompetenciasTIC().subscribe(
        res => {
          this.competencias =  res as CompetenciaTIC[];
        },
        err => console.log(err)
      );

      this.pcs.getPerfilesCompetencia().subscribe(
        res => {
          this.perCom =  res as PerfilCompetencia[];
        },
        err => console.log(err)
      );

      this.es.getCounter().subscribe(
        res => {
            this.submissions = res as Counter[];
            if (this.submissions.length === 0) {
              this.submitCounter.idSubmit = 1;
            } else {
               this.submitCounter.idSubmit = this.submissions[0].idSubmit + 1;
              }

        },
        err => console.log(err)
      );

    }


    onChange(): void {

      if ( this.selSex !== ''  &&  this.selEdad !== '' && this.selEstudios !== '') {
        this.startVisible = true;
      }

      if ( this.selPerfil !== ''  &&  this.selExperiencia !== '' && this.selSueldo !== 0) {

        this.continue23 = true;
        while (this.selCompetencias.length > 0) { this.selCompetencias.pop(); }
        while (this.test.length > 0) { this.test.pop(); }

        this.idPerfil = Array.from(this.perfiles.values()).filter((item: PerfilesTIC) =>
        item.titulo === this.selPerfil)[0].perfilID as number;
        this.selPerCom = Array.from(this.perCom.values()).filter((item: PerfilCompetencia) => item.perfilID === this.idPerfil);
        this.selPerCom.map( element => {
          this.selCompetencias.push(this.competencias[element.competenciaID - 1]);
        });
        this.selCompetencias.forEach( element => {
          const question: Question = new Question();
          question.idPerfil = this.idPerfil;
          question.idCompetencia = element.competenciaID as number;
          question.titulo = element.titulo;
          question.descripcion = element.descripcion;
          question.value = 0;
          this.test.push(question);
        });
      }
    }

    onChangeTest(): void {

        if (this.continue23 === true) {
          let allCheck = true;
          this.test.forEach(element => {
            if (element.value === 0) {
              allCheck = false;
            }
          });
          if (allCheck === true) { this.finalizar = true; }
        }
      }

    next(): void {
      if (this.step1 === true && this.startVisible === true) {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
      }

      if (this.step2 === true && this.continue23 === true) {
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
      }

      if (this.step3 === true && this.finalizar === true) {
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;

        const submit: Submission = new Submission();

        submit.idPerfilTIC = this.idPerfil;
        submit.experiencia = this.selExperiencia;
        submit.sueldo = this.selSueldo;
        submit.sexo = this.selSex;
        submit.date = this.date;
        this.es.saveSubmission(submit).subscribe();
        const mail: Correo = new Correo();
        mail.to = 'luisignacio.jimenez@cenits.es';
        mail.subject = 'Notificación Encuesta OLISTIC';

        const currentDate = new Date();

        mail.message =
        'Enviado en ' + currentDate.getDay().toString() + ' / ' + currentDate.getMonth().toString() +
        ' / ' + currentDate.getFullYear().toString() + ' --- ' + + currentDate.getHours() +  ':'
        + currentDate.getMinutes() + ':' + currentDate.getSeconds() +
        '<br><br>Los valores enviados son:<br><br>Sexo: '
        + this.selSex + '<br>Edad: ' + this.selEdad + '<br>NIvel de Estudios: ' + this.selEstudios
        + '<br>Perfil Profesional: ' + this.selPerfil + '<br>Experiencia: ' + this.selExperiencia +
        '<br>Sueldo Bruto: ' + this.selSueldo + ' €';

        this.ns.sendNotification(mail).subscribe();

        mail.to = 'olistic@cenits.es';
        this.ns.sendNotification(mail).subscribe();

        mail.to = 'felipe.lemus@cenits.es';
        this.ns.sendNotification(mail).subscribe();

        this.test.forEach( element => {
          const enc: Encuestas = new Encuestas();
          enc.idPerfil = element.idPerfil as number;
          enc.idCompetencia = element.idCompetencia;
          enc.value = element.value;
          enc.idSubmit = this.submitCounter.idSubmit;
          enc.date = this.date;
          this.es.saveEncuesta(enc).subscribe();
        });
        this.router.navigateByUrl('/');
      }
    }

}

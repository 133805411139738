import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmpAct } from 'src/app/models/EmpAct';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpActService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getRelations() {
      return this.http.get(`${this.API_URI}/empAct`);
    }

    // tslint:disable-next-line: typedef
    getEmpRelations(id: string) {
      return this.http.get(`${this.API_URI}/empAct/${id}`);
    }

    // tslint:disable-next-line: typedef
    saveRelation(act: EmpAct ) {
      return this.http.post(`${this.API_URI}/empAct`, act);
    }

    // tslint:disable-next-line: typedef
    deleteRelation(id: string) {
      return this.http.delete(`${this.API_URI}/empAct/${id}`);
    }

    // tslint:disable-next-line: typedef
    updateRelations(id: string, rel: EmpAct) {
      return this.http.put(`${this.API_URI}/empAct/${id}`, rel);
    }
}

import { Component, OnInit } from '@angular/core';
import { CompetenciaTIC } from 'src/app/models/CompetenciaTIC';
import { CompetenciasTICService } from 'src/app/services/bbdd/competencias-tic.service';

@Component({
  selector: 'app-competencias',
  templateUrl: './competencias.component.html',
  styleUrls: ['./competencias.component.css']
})
export class CompetenciasComponent implements OnInit {

// Vector que almacena el conjunto de competencias TIC
competencias: CompetenciaTIC[] = [];
// Competencia TIC seleccionada
competenciaDescripcion!: string;

constructor(private cs: CompetenciasTICService) { }

ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cs.getCompetenciasTIC().subscribe(
    res => {
        this.competencias = res as CompetenciaTIC[];
    },
    err => console.log(err)
    );
}
// Función que controla la accíon de selección de una competencia TIC
onClickCategoria(id: number): void {
    this.competenciaDescripcion = this.competencias[id - 1].descripcion;
}

}

<div class="container">
	<div class="form-group">  
        <h1>{{ titulo }}</h1>
        <div class="form-row">
            <div class="col-sm">
                <p class="pgreen">Provincia<br>
                <select [(ngModel)]="selProv" name="selectedProv" (ngModelChange)="onChange()" title="Selecciona una provincia">
                <option selected value="undefined">---Seleccione una Provincia---</option>
                <option value="Badajoz">Badajoz</option>
                <option value="Cáceres">Cáceres</option>
                </select></p>

                <p class="pgreen">Localidad<br>
                <select [(ngModel)]="selLoc" name="selectedLoc" (ngModelChange)="onChange()" title="Selecciona una localidad">
                    <option selected value="undefined">---Seleccione una Localidad---</option>
                    <option *ngFor="let localidad of localidades" [value]="localidad.loc">{{localidad.loc}}</option>
                </select></p>

                <p class="pgreen">Forma Jurídica<br>
                <select multiple [(ngModel)]="selFor" name="selectedFor" (ngModelChange)="onChange()">
                <option *ngFor="let forma of formas" [value]="forma">
                {{forma}}</option></select></p>
            </div>
        </div>

        <div class="form-row" *ngIf="barChartData?.length" style="margin: 30px;">
            <script src="../../../../node_modules/chart.js/dist/Chart.js"></script>
            <canvas baseChart [options]="lineChartOptions"
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                    [colors]="barColors"
            >
            </canvas>
        </div>

    </div>
</div>
  
<div class="container">
    <div class="form-group">
        <h1>{{ titulo }}</h1>
        <a style="font-size: 15px" [routerLink]="['/admin/editEmpresa', titulo]" >
            <img *ngIf="login" style="width: 25px; height: 25px;" src="../../../../assets/img/edit.png">
        </a>

        <div class="form-row">
            <div class="col-sm">
                <h2>Datos de la Empresa</h2>
                    <form>
                        <label>CIF: {{CIF}}</label>
                        <label>Teléfono: {{telefono}}</label>
                        <label>Número de Trabajadores: {{numTrabajadores}}</label>
                        <label>Página Web: <a href="{{web}}"> {{web}}</a></label>
                        <label>Email: {{correoElectronico}}</label>
                        <label>Descripción: {{descripcion}}</label>
                    </form>
                <h2>Datos Jurídicos</h2>
                    <div>
                        <label>Forma Jurídica: {{formaJuridica}}</label>
                        <label>Constitución: {{constitucion}}</label>
                        <label>Disolución: {{disolucion}}</label>
                    </div>
                <h2>Actividades Realizadas</h2>
                <ul *ngFor="let categoria of selCategorias">
                    {{categoria.categoriaID}} - {{categoria.titulo}}
                        <div class="container" *ngFor="let actividad of selActividades">
                            <li *ngIf="categoria.categoriaID === actividad.categoriaID">
                                    &nbsp;&nbsp;&nbsp;&nbsp;{{actividad.titulo}}
                            </li>
                        </div>
                        
                </ul> 

                <h2>Datos Geográficos</h2>
                <div>
                    <label>Provincia: {{provincia}}</label>
                    <label>Localidad: {{localidad}}</label>
                    <label>Dirección: {{direccionCompleta}}</label>
                </div>
                
                <div id="map" class="map"></div>
                </div>

    </div>
</div>

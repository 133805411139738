<div class="container">
	<div class="form-group">
        <h1>Aviso Legal y Condiciones de Uso</h1>
		<h3>1. Introducción</h3>
		<p>El presente documento tiene como finalidad el establecer y regular las normas de uso del Sitio <a [routerLink]="['../']">OLISTIC</a> (en adelante el "Sitio"), entendiendo por Sitio todas las páginas y sus contenidos propiedad de Fundación Computación y Tecnologías Avanzadas de Extremadura a las cuales se accede a través del dominio <a href="http://cenits.es">CenitS</a> y sus subdominios.<p>
		<p>La utilización del Sitio atribuye la condición de Usuario del mismo e implica la aceptación de todas las condiciones incluidas en el presente Aviso Legal. El Usuario se compromete a leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar el Sitio, ya que éste y sus condiciones de uso recogidas en el presente Aviso Legal pueden sufrir modificaciones.</p>

		<h3>2. Titularidad del Sitio Web</h3>
		<p>El titular del presente Sitio es Fundación Computación y Tecnologías Avanzadas de Extremadura (en adelante COMPUTAEX) con domicilio social en Carretera Nacional 521, Km 41.8, CP 10071, Cáceres (España) y con CIF G10391704.</p>
		<p>Ud. puede ponerse en contacto con nosotros en el siguiente número de teléfono o e-mail:</p>
		<ul>
			<li>Telefono: 927 04 90 70</li>
			<li>E-mail: <a href="mailto:info@cenits.es">info@cenits.es</a></li>
		</ul>

		<h3>3. Propiedad Intelectual e Industrial</h3>
		<p>Los derechos de propiedad intelectual de este Sitio, son titularidad de COMPUTAEX.</p>
		<p>La reproducción, distribución, comercialización o transformación, total o parcial, no autorizadas del contenido del Sitio, a no ser que sea para uso personal y privado, constituye una infracción de los derechos de propiedad intelectual de COMPUTAEX. Igualmente, todas las marcas o signos distintivos de cualquier clase contenidos en el Sitio están protegidos por Ley. La utilización no autorizada de la información contenida en este Sitio, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de COMPUTAEX, pueden dar lugar al ejercicio de las acciones que legalmente correspondan y, si procede, a las responsabilidades que de dicho ejercicio se deriven.</p>

		<h3>4. Exclusión de Responsabilidad</h3>
		<p>El contenido, programas, información y/o consejos expresados en este Sitio deben entenderse como simplemente orientativos. COMPUTAEX no responde de ninguna forma de la efectividad o exactitud de los mismos, quedando exenta de cualquier responsabilidad contractual o extracontractual con los Usuarios que haga uso de ellos, ya que son éstas las que deberán decidir según su criterio la oportunidad de los mismos.</p>
		<p>En este Sitio se pueden publicar contenidos aportados por terceras personas o empresas, COMPUTAEX no responde de la veracidad y exactitud de los mismos, quedando exenta de cualquier responsabilidad contractual o extracontractual con los Usuarios que hagan uso de ellos.</p>
		<p>COMPUTAEX se reserva el derecho de modificar el contenido del Sitio sin previo aviso y sin ningún tipo de limitación.</p>
		<p>COMPUTAEX declina cualquier responsabilidad por los eventuales daños y perjuicios que puedan ocasionarse por la falta de disponibilidad y/o continuidad de este Sitio y de los servicios que se ofrecen en él.</p>
		<p>COMPUTAEX no garantiza la ausencia de virus ni de otros elementos en la web que puedan producir alteraciones en su sistema informático. COMPUTAEX declina cualquier responsabilidad contractual o extracontractual con los Usuarios que hagan uso de ello y tuviera perjuicios de cualquier naturaleza ocasionados por virus informáticos o por elementos informáticos de cualquier índole.</p>
		<p>COMPUTAEX declina cualquier responsabilidad por los servicios que eventualmente pudieran prestarse en el sitio por parte de terceros.</p>
		<p>COMPUTAEX declina cualquier responsabilidad por los servicios y/o información que se preste en otros sitios enlazados con este.</p>
		<p>COMPUTAEX no controla ni ejerce ningún tipo de supervisión en Sitios Webs de terceros. Aconsejamos a los Usuarios de los mismos a actuar con prudencia y consultar las eventuales condiciones legales que se expongan en dichas webs.</p>
		<p>Los Usuarios que remitan cualquier tipo de información a COMPUTAEX se comprometen a que la misma sea veraz y que no vulnere cualquier derecho de terceros ni la legalidad vigente.</p>

		<h3>5. Condiciones de Uso del Portal para los Usuarios</h3>
		<p>El acceso al presente Sitio es gratuito salvo en lo relativo al coste de la conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por los usuarios.</p>
		<p>Queda expresamente prohibido el uso del Sitio con fines lesivos de bienes o intereses de COMPUTAEX o de terceros o que de cualquier otra forma sobrecarguen, dañen o inutilicen las redes, servidores y demás equipos informáticos (hardware) o productos y aplicaciones informáticas (software) de COMPUTAEX o de terceros.</p>
		<p>En el caso de que el Usuario tuviera conocimiento de que los Sitios enlazados remiten a páginas cuyos contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o contrarios a la moral le agradeceríamos que se pusiera en contacto con COMPUTAEX.</p>

		<h3>6. Política de Protección de Datos Personales</h3>
		<p>En el siguiente enlace puede ampliar la información acerca de nuestra <a [routerLink]="['../politica']">política de privacidad</a> de los datos personales.</p>

		<h3>7. Recomendaciones de Visualización</h3>
		<p>En este apartado se incluirán las recomendaciones para la mejor visualización de la web en cuanto a navegador, resolución de pantalla, etc.</p>

		<h3>8. Legislación</h3>
		<p>El presente Aviso Legal se rige en todos y cada uno de sus extremos por la legislación española.</p>

		<h3>9. Contacte con Nosotros</h3>
		<p>Si tiene Ud. cualquier pregunta sobre las condiciones reflejadas en este Aviso Legal, o si le gustaría hacer cualquier sugerencia o recomendación, por favor diríjase a nosotros a través de la siguiente dirección de correo electrónico: <a href="mailto:info@cenits.es">info@cenits.es</a></p>

	</div>
</div>

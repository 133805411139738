<div class="container">
	<div class="form-group">
	    <h1>Memorias Anuales</h1>
       
        <p>Las Tecnologías de la Información y las Comunicaciones (TIC) se han convertido en el principal motor de la emergente economía digital y, como tal, merecían ser analizadas en profundidad. El proyecto TaxoTIC se inició en el año 2013 con el objetivo de visibilizar las TIC y a aquellas empresas y profesionales de Extremadura que prestan servicios relacionados con las mismas.</p>
        
        <p> trabajo realizado desde el año 2013 ha permitido el desarrollo de un observatorio del Sector TIC en la región, en el que se incluye información de las empresas y profesionales del sector, así como de la formación TIC profesional y universitaria.</p>
            
        <p>Durante el año 2013 se identificaron y clasificaron aquellas actividades que, desde el punto de vista del equipo de CénitS, podrían ser consideradas TIC. Partiendo de dicha clasificación, se identificó y caracterizó el sector, analizando todas las posibles empresas cuyas actividades principales estuvieran entre las establecidas como TIC para, finalmente, analizar el sector en Extremadura desde un punto de vista interno y externo (comparándolo con otros sectores más tradicionales de la región).</p>

        <img src="../../../../assets/img/Empresas_sector.png">
        <p>En 2014 se analizó el sector TIC en España y Europa para ver las diferencias existentes con Extremadura. Además, se diseñó y desarrolló la plataforma OpenData OLISTIC (Observatorio regionaL de Información del Sector TIC) que contiene información de las empresas que componen el sector y las actividades TIC que desempeñan. Finalmente, con el fin de entender mejor el sector, se realizó un estudio desde el punto de vista de los directivos de las empresas TIC, con una encuesta y una serie de entrevistas a directivos de carácter regional y nacional.</p>

        <p>En 2015 se realizó la automatización de ciertas actividades asociadas al proyecto, como son la detección de constituciones y disoluciones de sociedades y se desarrollaron nuevas aplicaciones para la plataforma Opendata. De nuevo, con el fin de analizar el sector desde todos los puntos de vista posibles, se realizó un estudio del equipamiento y uso de las TIC en Extremadura, ofreciendo de este modo una visión de las TIC más orientada a sus usuarios finales.</p>

        <<img src="../../../../assets/img/contrib_pib.png">
        <p>Durante los años 2016 y 2017 se añadió a la plataforma OLISTIC información sobre los perfiles profesionales TIC y sus competencias asociadas. Para su elaboración se utilizó como referencia el e-CF (European e-Competence Framework) que en 2016 se transformó en un estándar europeo y que fue publicado oficialmente como la norma EN 16234-1.</p>

        <p>El marco e-CF define 23 perfiles profesionales y 40 competencias. En el momento de la elaboración del proyecto no se contaba con ninguna traducción oficial del mismo al castellano y ésta fue realizada, en el marco del proyecto, por los propios técnicos de CénitS.</p>

        <p>La Fundación COMPUTAEX considera que la información aportada por el proyecto es importante para Extremadura y, sobre todo, para el propio Sector TIC. Por ello, durante 2018, se continuó la labor de recopilación y análisis de toda la información relevante para el Sector TIC regional.</p>
        <hr>
        <div>
               
                <p class="pgreen">Memorias Anuales del proyecto TaxoTIC    
                    <img class="icon" src="../../../../assets/img/icono-pdf.png">
                    <a href="../../../../assets/files/memoria-taxonomtic-2013.pdf" > 2013 </a>
                    <a href="../../../../assets/files/memoria-taxonomtic-2014.pdf" > 2014 </a>
                    <a href="../../../../assets/files/memoria-taxonomtic-2015.pdf" > 2015 </a>
                    <a href="../../../../assets/files/memoria-taxonomtic-2016.pdf" > 2016 </a>
                    <a href="../../../../assets/files/memoria-taxonomtic-2017.pdf" > 2017 </a>
                    <a href="../../../../assets/files/memoria-taxonomtic-2018.pdf" > 2018 </a>
                    <a href="../../../../assets/files/memoria-taxotic-2019.pdf" > 2019 </a>
                </p>
        </div>
		
	</div>
</div>

<div class="container">
	<div class="form-group">
        <h1>Eliminar Empresa</h1>
        <div class="col-sm">
        <div >
            <select [(ngModel)]="selTit" name="selectedTitulo">
                <option selected value="undefined">---Seleccione una Empresa a editar---</option>
                <option *ngFor="let tit of titulos" [value]="tit">
                {{tit}}</option></select>
        </div>
        <br>
        <br>
        <div >
          <button  class="btn btn-success" (click)="delete()">Eliminar</button>
          <button  class="btn btn-danger" (click)="return()">Volver</button>
        </div>
    </div>
  </div>
</div>

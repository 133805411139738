<div class="container">
    
   <form>
        <div class="form-group">
            <h1>{{ titulo }}</h1>
            <div class="form-row" >
                <div class="col-sm">   
                    <p class="pgreen">Provincia<br>
                    <select [(ngModel)]="selProv" name="selectedProv" (ngModelChange)="onChange()" title="Seleccione una provincia">
                        <option value="undefined" disabled selected>---Seleccione una Provincia---</option>
                            <option value="Badajoz">Badajoz</option>
                            <option value="Caceres">Cáceres</option>
                        </select>
                        </p> 
                </div>
                <div class="col-sm"> 
                        <p class="pgreen">Localidad<br>
                        <select [(ngModel)]="selLoc" name="selectedLoc" (ngModelChange)="onChange()" title="Selecciona una localidad">
                            <option selected disabled value="undefined">---Seleccione una Localidad---</option>
                            <option *ngFor="let localidad of localidades" [value]="localidad.loc">{{localidad.loc}}</option>
                        </select></p>      
                </div>
            </div>
    
            <div class="form-row">
                <div class="col-sm">
                        <p class="pgreen">Forma Jurídica<br>
                        <select multiple [(ngModel)]="selFor" name="selectedFor" (ngModelChange)="onChange()">
                            <option *ngFor="let forma of formas" [value]="forma">
                            {{forma}}</option></select></p>
                </div>
                <div class="col-sm">
                        <p class="pgreen">Categoria TIC<br>
                        <select multiple [(ngModel)]="selCategoria" name="selectedCat" (ngModelChange)="onChange()">
                        <option *ngFor="let categoria of categorias" [value]="categoria">
                        {{categoria.titulo}}</option></select></p>
                </div>
            </div>
            
            
            <div class="row">
                <div class="col-sm">
                    <p class="pgreen">Categorias TIC<br>
                    <div class="row">
                        <script src="../../../../../node_modules/chart.js/dist/Chart.js"></script>                    
                        <canvas baseChart 
                            [data]="doughnutChartData1"
                            [labels]="doughnutChartLabels1"
                            [chartType]="doughnutChartType1"
                            [options]="doughnutChartOptions1"
                            [colors]="doughnutColors1"
                            >
                        </canvas>
                    </div>

                    <p class="pgreen">Actividades TIC<br>
                    <div class="row">
                        <script src="../../../../../node_modules/chart.js/dist/Chart.js"></script>                        
                        <canvas baseChart 
                            [data]="doughnutChartData2"
                            [labels]="doughnutChartLabels2"
                            [options]="doughnutChartOptions2"
                            [chartType]="doughnutChartType2"
                            [colors]="doughnutColors2"
                            >
                        </canvas>
                    </div>                         

                </div>
            </div>

        </div>
    </form>
</div>
  

<!-- <div >
    <p class="pgreen">Actividades TIC<br>
                <script src="../../../../node_modules/chart.js/dist/Chart.js"></script>
                <div style="margin: 30px">
                    <canvas baseChart 
                    [data]="doughnutChartData2"
                    [labels]="doughnutChartLabels2"
                    [options]="doughnutChartOptions2"
                    [chartType]="doughnutChartType2"
                    [colors]="doughnutColors2"
                    >
                    </canvas>
                </div> 
            </div> -->
<!-- 
            <p class="pgreen">Categorias TIC<br>
                <script src="../../../../../node_modules/chart"></script>
                <div style="margin: 30px">
                <canvas baseChart 
                    [data]="doughnutChartData1"
                    [labels]="doughnutChartLabels1"
                    [chartType]="doughnutChartType1"
                    [options]="doughnutChartOptions1"
                    [colors]="doughnutColors1"
                    >
                </canvas> -->
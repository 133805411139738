import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Usuario } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutenticationService {

    API_URI = environment.server_address;
    private loggedSource = new BehaviorSubject(false);
    logged = this.loggedSource.asObservable();
    user!: Usuario;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getUsuarios() {
        return this.http.get(`${this.API_URI}/usuarios`);
    }
    // tslint:disable-next-line: typedef
    getUsuariobyUsuario(user: string) {
        return this.http.get(`${this.API_URI}/usuarios/${user}`);
    }
    // tslint:disable-next-line: typedef
    saveUsuario(user: Usuario ) {
        return this.http.post(`${this.API_URI}/usuarios`, user);
    }
    // tslint:disable-next-line: typedef
    deleteUsuario(id: string) {
        return this.http.delete(`${this.API_URI}/usuarios/${id}`);
    }
    // tslint:disable-next-line: typedef
    updateUsuario(id: string, user: Usuario) {
        return this.http.put(`${this.API_URI}/usuarios/${id}`, user);
    }
    // tslint:disable-next-line: typedef
    isLogged() {
        return this.logged;
    }
    // tslint:disable-next-line: typedef
    setLogged(val: boolean, user: Usuario) {
        this.user = user;
        this.loggedSource.next(val);

    }

    getUser(): Usuario {return this.user; }
}

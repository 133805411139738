import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/gui/navigation/navigation.component';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { MainviewComponent } from './components/views/mainview/mainview.component';
import { ActividadesTICComponent } from './components/views/actividades-tic/actividades-tic.component';
import { PorcentajeTICComponent } from './components/views/porcentaje-tic/porcentaje-tic.component';
import { ChartsModule } from 'ng2-charts';
import { DirectorioComponent } from './components/views/directorio/directorio.component';
import { EvolucionComponent } from './components/views/evolucion/evolucion.component';
import { EmpresaSiteComponent } from './components/views/empresa-site/empresa-site.component';
import { MapaEmpComponent } from './components/views/mapa-emp/mapa-emp.component';
import { HaversineService } from 'ng2-haversine';
import { CompetenciasComponent } from './components/views/competencias/competencias.component';
import { PerfilesTICComponent } from './components/views/perfiles-tic/perfiles-tic.component';
import { EncuestaComponent } from './components/views/encuesta/encuesta.component';
import { MemoriasComponent } from './components/views/memorias/memorias.component';
import { AvisoComponent } from './components/views/aviso/aviso.component';
import { PoliticaComponent } from './components/views/politica/politica.component';
import { MapawebComponent } from './components/views/mapaweb/mapaweb.component';
import { ContactoComponent } from './components/views/contacto/contacto.component';
import { LoginComponent } from './components/gui/login/login.component';
import { AdminComponent } from './components/gui/admin/admin.component';
import { AddEmpresaComponent } from './components/gui/add-empresa/add-empresa.component';
import { EditEmpresaComponent } from './components/gui/edit-empresa/edit-empresa.component';
import { DelEmpresaComponent } from './components/gui/del-empresa/del-empresa.component';

const routes: Routes = [
   { path: '', component: MainviewComponent },
   { path: 'actividadesTIC', component: ActividadesTICComponent },
   { path: 'porcentaje', component: PorcentajeTICComponent },
   { path: 'directorio',
    children : [
       { path: '', component: DirectorioComponent },
       { path: 'descripcionEmpresa',
       children : [
         {path : ':titulo', component: EmpresaSiteComponent}
        ]}
      ]},
   { path: 'evolucion', component: EvolucionComponent },
   { path: 'mapa', component: MapaEmpComponent },
   { path: 'perfiles', component: PerfilesTICComponent },
   { path: 'competencias', component: CompetenciasComponent },
   { path: 'encuesta', component: EncuestaComponent },
   { path: 'memorias', component: MemoriasComponent },
   { path: 'aviso', component: AvisoComponent },
   { path: 'politica', component: PoliticaComponent },
   { path: 'mapaweb', component: MapawebComponent },
   { path: 'contacto', component: ContactoComponent },
   { path: 'login', component: LoginComponent },
   { path: 'admin',
    children : [
      { path: '', component: AdminComponent},
      { path: 'addEmpresa', component: AddEmpresaComponent},
      { path: 'editEmpresa',
      children : [
          {path : ':titulo' , component: EditEmpresaComponent}
        ]},
      { path: 'delEmpresa', component: DelEmpresaComponent}]
    },
   { path: '**', component: MainviewComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    MainviewComponent,
    ActividadesTICComponent,
    PorcentajeTICComponent,
    DirectorioComponent,
    EvolucionComponent,
    EmpresaSiteComponent,
    MapaEmpComponent,
    CompetenciasComponent,
    PerfilesTICComponent,
    EncuestaComponent,
    MemoriasComponent,
    AvisoComponent,
    PoliticaComponent,
    MapawebComponent,
    ContactoComponent,
    LoginComponent,
    AdminComponent,
    AddEmpresaComponent,
    EditEmpresaComponent,
    DelEmpresaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    FormsModule,
    HttpClientModule,
    ChartsModule
  ],
  exports: [RouterModule],
  providers: [
    HaversineService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PerfilesTIC } from 'src/app/models/PerfilesTIC';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerfilesTICService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getPerfilesTIC(){
      return this.http.get(`${this.API_URI}/perfilesTIC`);
    }
    // tslint:disable-next-line: typedef
    getOnePerfilTIC(id: string){
      return this.http.get(`${this.API_URI}/perfilesTIC/${id}`);
    }
    // tslint:disable-next-line: typedef
    savePerfilTIC(cat: PerfilesTIC ){
      return this.http.post(`${this.API_URI}/perfilesTIC`, cat);
    }
    // tslint:disable-next-line: typedef
    deletePerfilTIC(id: string){
      return this.http.delete(`${this.API_URI}/perfilesTIC/${id}`);
    }
    // tslint:disable-next-line: typedef
    updatePerfilTIC(id: string, cat: PerfilesTIC){
      return this.http.put(`${this.API_URI}/perfilesTIC/${id}`, cat);
    }

}

<div class="container">
	<div class="block">
        <h1>Actividades TIC</h1>
		<p>Las empresas del sector TIC suelen encontrarse muy diversificadas y resulta complicado
			 definir una única actividad principal. Por ello, como resultado del estudio realizado 
			 sobre los códigos CNAE 2009, COMPUTAEX propone una nueva clasificación de actividades 
			 TIC que permita indicar las diferentes actividades desempeñadas por las empresas. De 
			 este modo, y con una simple asociación empresa-códigos, es posible tener una idea 
			 clara y certera de las actividades desarrolladas por una empresa del sector TIC.</p>
		
		<div style="text-align: center;">
			<button id="buttonList" *ngFor="let categoria of categorias" (click)="onClickCategoria(categoria.categoriaID!)">{{categoria.titulo}}</button>
		</div>
		<p *ngIf="categoriaDescripcion" id="cat_descripcion">{{categoriaDescripcion}}</p>
		<div *ngIf="categoriaDescripcion" style="text-align: center;">
				<button *ngFor="let actividad of actividades" (click)="actividadDescripcion=actividad.descripcion">{{actividad.titulo}}</button>
		</div>
		<p *ngIf="actividadDescripcion" id="act_descripcion">{{actividadDescripcion}}</p>
	</div>
</div>
<div class="container">
	<div class="block">
        <h1>Competencias TIC</h1>
        <p>El framework europeo para las competencias electrónicas (e-CF) define 40 competencias que se clasifican en las cinco áreas principales de las TIC y se encuentran relacionadas con el Marco Europeo de Cualificaciones (EQF).</p>		
  
		<div style="text-align: center;">
			<button id="buttonList" *ngFor="let competencia of competencias" (click)="onClickCategoria(competencia.competenciaID!)">{{competencia.titulo}}</button>
		</div>
		<p *ngIf="competenciaDescripcion" id="com_descripcion">{{competenciaDescripcion}}</p>
	</div>
	
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActividadesTICService {

  API_URI = environment.server_address;

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  getActividadesTIC() {
    return this.http.get(`${this.API_URI}/actividadesTIC`);
  }
  // tslint:disable-next-line: typedef
  getActividadesTICbyCategoria(id: string) {
    return this.http.get(`${this.API_URI}/actividadesTIC/byCat/${id}`);
  }
  // tslint:disable-next-line: typedef
  getOneActividadTIC(id: string) {
    return this.http.get(`${this.API_URI}/actividadesTIC/${id}`);
  }
  // tslint:disable-next-line: typedef
  saveActividadTIC(cat: ActividadTIC ) {
    return this.http.post(`${this.API_URI}/actividadesTIC`, cat);
  }
  // tslint:disable-next-line: typedef
  deleteActividadTIC(id: string) {
    return this.http.delete(`${this.API_URI}/actividadesTIC/${id}`);
  }
  // tslint:disable-next-line: typedef
  updateActividadTIC(id: string, cat: ActividadTIC) {
    return this.http.put(`${this.API_URI}/actividadesTIC/${id}`, cat);
  }
}

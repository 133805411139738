import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Correo } from 'src/app/models/Correo';
import { Mensaje } from 'src/app/models/Mensaje';
import { MensajesService } from 'src/app/services/bbdd/mensajes.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  // Variables del formulario
  nombre!: string;
  asunto!: string;
  categoria!: string;
  texto!: string;
  correo!: string;

  constructor(private ms: MensajesService, private router: Router, private ns: NotifierService) { }

  ngOnInit(): void {
      window.scrollTo(0, 0);
  }

  // Controlador de almacenamiento en la base de datos y notificación de mensajes
  sendMail(): void {
      const data: Mensaje = {
          nombre : this.nombre,
          correo : this.correo,
          asunto : this.asunto,
          categoria : this.categoria,
          texto : this.texto
      };


      this.ms.saveMensaje(data).subscribe((res: any) => {
          this.mailService();
          this.resetForm();
          this.return();
      });
  }

  mailService(): void {
      const mail: Correo = new Correo();
      const currentDate = new Date();

      mail.message =
      'Enviado en ' + currentDate.getDay().toString() + ' / ' + currentDate.getMonth().toString() +
      ' / ' + currentDate.getFullYear().toString() + ' --- ' + + currentDate.getHours() +  ':'
      + currentDate.getMinutes() + ':' + currentDate.getSeconds() +
      '<br><br>Se ha realizado una petición de contacto cuya infomación es la siguiente:<br><br>Asunto: '
      + this.asunto + '<br>Correo: ' + this.correo + '<br>Nombre del contacto: ' + this.nombre
      + '<br>Categoria: ' + this.categoria + '<br>Mensaje: ' + this.texto;


      mail.subject = 'Notificación de Contacto OLISTIC';

      mail.to = 'luisignacio.jimenez@cenits.es';
      this.ns.sendNotification(mail).subscribe();

      mail.to = 'olistic@cenits.es';
      this.ns.sendNotification(mail).subscribe();

      mail.to = 'felipe.lemus@cenits.es';
      this.ns.sendNotification(mail).subscribe();
  }

  // Función de reinicio del formulario
  resetForm(): void {
      this.nombre = '';
      this.asunto = '';
      this.correo = '';
      this.texto = '';
      this.categoria = '';
  }

  // Funcion de retorno a la pagina principal
  return(): void {
      this.router.navigateByUrl('/');
  }



}

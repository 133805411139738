import { Component, OnInit } from '@angular/core';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';

import { CompetenciaTIC } from 'src/app/models/CompetenciaTIC';
import { PerfilCompetencia } from 'src/app/models/PerfilCompetencia';
import { PerfilesTIC } from 'src/app/models/PerfilesTIC';

import { CompetenciasTICService } from 'src/app/services/bbdd/competencias-tic.service';
import { PerfilCompetenciaService } from 'src/app/services/bbdd/perfil-competencia.service';
import { PerfilesTICService } from 'src/app/services/bbdd/perfiles-tic.service';

@Component({
  selector: 'app-perfiles-tic',
  templateUrl: './perfiles-tic.component.html',
  styleUrls: ['./perfiles-tic.component.css']
})
export class PerfilesTICComponent implements OnInit {

    titulo = 'Perfiles profesionales TIC';

    texto = 'El CEN ICT Skills Workshop ha elaborado un conjunto de Perfiles Profesionales TIC europeos. Utilizando el Marco Europeo de Competencia Electrónica (e-CF) como base para la identificación de las competencias asociadas a cada uno de los perfiles. Esta iniciativa surge como respuesta a la gran cantidad de perfiles TIC utilizados hoy en Europa. La primera versión contaba con 23 perfiles TIC representativos estructurados en seis familias. En el año 2018 se realizó una actualización del conjunto de perfiles resultando en 30 Perfiles TIC representativos distribuidos en siete familias de Perfil que cubren todo el proceso de Negocio TIC.';

    perfiles: PerfilesTIC[] = [];
    perfilDescripcion!: string;

    perCom: PerfilCompetencia[] = [];
    selPerCom: PerfilCompetencia[] = [];

    competencias: CompetenciaTIC[] = [];
    selCompetencias: CompetenciaTIC[] = [];
    competenciaDescripcion!: string;


    constructor(private ps: PerfilesTICService, private pcs: PerfilCompetenciaService, private cs: CompetenciasTICService) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.ps.getPerfilesTIC().subscribe(
        res => {
          this.perfiles =  res as PerfilesTIC[];
        },
        err => console.log(err)
      );

      this.pcs.getPerfilesCompetencia().subscribe(
        res => {
          this.perCom =  res as PerfilCompetencia[];
        },
        err => console.log(err)
      );

      this.cs.getCompetenciasTIC().subscribe(
        res => {
          this.competencias =  res as CompetenciaTIC[];
        },
        err => console.log(err)
        );
    }

    onClickPerfil(id: number): void {
      this.perfilDescripcion = this.perfiles[id - 1].descripcion;

      while (this.selPerCom.length > 0) { this.selPerCom.pop(); }
      while (this.selCompetencias.length > 0) { this.selCompetencias.pop(); }

      this.competenciaDescripcion = '';

      this.perCom.forEach(element => {
        if (element.perfilID === id) {

            this.selCompetencias.push( Array.from(this.competencias.values()).filter((item: CompetenciaTIC) =>
            item.competenciaID === element.competenciaID)[0]);

        }
      });
    }

    onClickCompetencia(competencia: string): void {
      this.competenciaDescripcion = competencia;
    }

}

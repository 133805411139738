import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmpCat } from 'src/app/models/EmpCat';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpCatService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getRelations() {
      return this.http.get(`${this.API_URI}/empCat`);
    }

    // tslint:disable-next-line: typedef
    getEmpRelations(id: string) {
      return this.http.get(`${this.API_URI}/empCat/${id}`);
    }

    // tslint:disable-next-line: typedef
    saveRelation(cat: EmpCat ) {
      return this.http.post(`${this.API_URI}/empCat`, cat);
    }

    // tslint:disable-next-line: typedef
    deleteRelation(id: string) {
      return this.http.delete(`${this.API_URI}/empCat/${id}`);
    }

    // tslint:disable-next-line: typedef
    updateRelation(id: string, rel: EmpCat) {
      return this.http.put(`${this.API_URI}/empCat/${id}`, rel);
    }
}

<div class="container">
	<div class="form-group">
        <h1>Directorio de Empresas</h1>
		<div class="form-row">
			<div class="col-sm">
				<form>
					<p class="pgreen">Categorias TIC<br>
					<select multiple [(ngModel)]="selCat" name="selectedCat" (ngModelChange)="onChange()">
						<option *ngFor="let categoria of categorias" [value]="categoria">
						{{categoria.titulo}}</option></select></p>
				</form>
				<form>
					<p class="pgreen">Actividades TIC<br>
					<select multiple [(ngModel)]="selAct" name="selectedAct" (ngModelChange)="onChange()">
						<option *ngFor="let actividad of actividades" [value]="actividad">
						{{actividad.titulo}}</option></select></p>
				</form>
				<form>
					<p class="pgreen">Provincia<br>
						<select [(ngModel)]="selProv" name="selectedProv" (ngModelChange)="onChange()" title="Selecciona una provincia">
							<option selected value="undefined">---Seleccione una Provincia---</option>
							<option value="Badajoz">Badajoz</option>
							<option value="Cáceres">Cáceres</option>
						</select></p>

				</form>
				<form>
					<p class="pgreen">Localidad<br>
						<select [(ngModel)]="selLoc" name="selectedLoc" (ngModelChange)="onChange()" title="Selecciona una localidad">
								<option selected value="undefined">---Seleccione una Localidad---</option>
								<option *ngFor="let localidad of localidades" [value]="localidad.loc">{{localidad.loc}}</option>
						</select></p>
				</form>
			</div>
			<div class="col-sm" >
				<form>
					<p class="pgreen">Nombre o razón social<br>
					<input type="text" [(ngModel)]="selName" name="selectedName" (ngModelChange)="onChange()" title="Escribe el nombre de una empresa"></p>
				</form>

				<form>
					<p class="pgreen">Constitución<br>
					<input type="text" [(ngModel)]="selCons" name="selectedCons" (ngModelChange)="onChange()" title="Escribe el año de constitución de una empresa"></p>
				</form>
				<form>
					<p *ngIf="!isDis" class="pgreen">Disolución<br>
                    <input type="text" [(ngModel)]="selDis" name="selectedDis" (ngModelChange)="onChange()" title="Escribe el año de disolución de una empresa"></p>
                    <p class="pgreen">Excluir Empresas Disueltas<br>
                    <input type="checkbox" [(ngModel)]="isDis" name="selectedInDis" (ngModelChange)="onChange()" title="Al seleccionar la opción se eliminarán las empresas disueltas"></p>
				</form>
				<form>
					<p class="pgreen">Pura TIC<br>
					<select [(ngModel)]="selPT" name="selectedPT" (ngModelChange)="onChange()" title="Selecciona una condición">
						<option selected value="undefined">---Seleccione una opción---</option>
						<option value="SI">Si</option>
						<option value="NO">No</option>
					</select>
				</form>
				<form>
					<button class="btn btn-success" (click)="downloadCSV()">Descargar CSV</button>
				</form>
				<form><p class="pgreen">Empresas Seleccionadas: {{numEmpresas}} de {{totalEmpresas}}</p></form>
				
			</div>
        </div>
        <div class="form-row">
            <div class="col-sm" >				
                <div class="table-responsive-sm">
                    <table class="table table-bordered table-striped mb-0">
                    <thead class="thead-dark">
                        <tr>
                        <th>Nombre / Razón Social</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of empresasSelect">
                            <td><a [routerLink]="['descripcionEmpresa', row.titulo]">{{row.titulo}}</a></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
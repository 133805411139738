import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { AnosLabel } from 'src/app/models/AnosLabel';
import { AnosValue } from 'src/app/models/AnosValue';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { Localidad } from 'src/app/models/Localidad';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

@Component({
  selector: 'app-evolucion',
  templateUrl: './evolucion.component.html',
  styleUrls: ['./evolucion.component.css']
})
export class EvolucionComponent implements OnInit {

    titulo = 'Evolución de Empresas';

    empresasAll: EmpresaCompleta[] = [];
    empresasSelect: EmpresaCompleta[] = [];
    constituidas: AnosValue[] = [];
    disueltas: AnosValue[] = [];
    localidades: Localidad[] = [];
    localidadesExtremadura: Localidad[] = [];
    localidadesBadajoz: Localidad[] = [];
    localidadesCaceres: Localidad[] = [];

    formas: string[] = ['Autónomo', 'Sociedad anónima', 'Sociedad de responsabilidad limitada',
    'Sociedad colectiva', 'Sociedad comanditaria', 'Comunidad de bienes y herencias yacentes',
    'Sociedad cooperativa', 'Asociación o fundación', 'Comunidad de propietarios en régimen de propiedad horizontal',
    'Sociedad civil, con o sin personalidad jurídica', 'Entidad extranjera', 'Corporación local',
    'Organismo público', 'Congregación o institución religiosa', 'Órgano de la Administración del Estado y de las Comunidades Autónomas',
    'Unión Temporal de Empresa', 'Establecimiento permanente de entidades no residentes en España', 'Otros'];
    selFor: string[] = [];
    selLoc!: string;
    selProv!: string;
    selDataC: number[] = [];
    selDataD: number[] = [];

    dataC: number[] = [0];
    dataD: number[] = [0];
    barChartData = [];
    barChartLabels: string[] = [];
    barChartType: ChartType = 'bar';
    barChartLegend = true;
    barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      maintainAspectRatio: true
    };
    lineChartOptions: any = {
      legend : {
          labels : {
            fontColor : '#333'
          },
          display: false
      },
     tooltips: {
        enabled: true
     }
    };
    barColors: any[] = [{
        backgroundColor: '#4e6422',
        hoverBackgroundColor: '#4e6422',
        borderColor: '#4e6422',
        hoverBorderColor: '#4e6422'
      },
      {
        backgroundColor: '#8f3d28',
        hoverBackgroundColor: '#8f3d28',
        borderColor: '#8f3d28',
        hoverBorderColor: '#22D8f3d28F14'
      }];

    constructor(private es: EmpresasService) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.loadData();
      this.updateData();
      this.formas.sort((one, two) => (one > two ? 1 : -1));
    }

    loadData(): void {
      this.es.getEmpresasComp().subscribe(
        res => {
          this.empresasAll = res as EmpresaCompleta[];
          this.empresasSelect = this.empresasAll;
         },
        err => console.log(err)
      );

      this.es.getLocalidades().subscribe(
        res => {
          this.localidades = res as Localidad[];
          this.localidades.shift();
          this.localidadesExtremadura = this.localidades;
        },
        err => console.log(err)
      );

      this.es.getLocalidadesBadajoz().subscribe(
        res => {
          this.localidadesBadajoz = res as Localidad[];
        },
        err => console.log(err)
      );

      this.es.getLocalidadesCaceres().subscribe(
        res => {
          this.localidadesCaceres = res as Localidad[];
        },
        err => console.log(err)
      );

      this.es.getConstitucion().subscribe(
        res => {
          const data: number[] = [];
          (res as AnosValue[]).map(x => {data.push(x.value); });
          this.dataC = data;
          this.dataC.shift();

         },
        err => {
          return console.log(err);
        }

      );

      this.es.getDisoluccion().subscribe(
        res => {
          const data: number[] = [];
          (res as AnosValue[]).map( x => {data.push(x.value); });
          this.dataD = data;
          this.dataD.shift();
         },
        err => {
          return console.log(err);
        }
      );

      this.es.getConstitucionLabels().subscribe(
        res => {
          const data: string[] = [];

          (res as AnosLabel[]).map(x => { data.push(x.label); });
          this.barChartLabels = data;
          this.barChartLabels.shift();
         },
        err => console.log(err)
      );


    }

    async updateData(): Promise<void> {
      await this.delay(300);
      while (this.barChartLabels.length > 0) { this.barChartLabels.pop(); }
      while (this.dataC.length > 0) { this.dataC.pop(); }
      while (this.dataD.length > 0) { this.dataD.pop(); }

      this.empresasSelect.forEach(x => {
        if (this.barChartLabels.length === 0) {
          this.barChartLabels.push(x.constitucion);
        }
        if (this.barChartLabels.length > 0 && !this.barChartLabels.includes(x.constitucion)) {
           this.barChartLabels.push(x.constitucion);
        }
        if (this.barChartLabels.length > 0 && !this.barChartLabels.includes(x.disolucion)) {
          this.barChartLabels.push(x.disolucion);
        }
      });
      this.barChartLabels.sort((one, two) => (one > two ? 1 : -1));
      this.barChartLabels.forEach(element => {
        let cont = 0;
        this.empresasSelect.map(x => { if (x.constitucion === element) {cont = cont + 1; } });
        this.dataC.push(cont);
        cont = 0;
        this.empresasSelect.map(x => { if (x.disolucion === element) {cont = cont + 1; } });
        this.dataD.push(cont);
      });
      this.dataC.shift();
      this.dataD.shift();
      if (this.barChartLabels.includes('')) {
        this.barChartLabels.shift();
      }
      this.barChartData = [{data: this.dataC, label: 'Constituidas'} as never,
      {data: this.dataD, label: 'Disueltas'} as never];
    }

    onChange(): void {

      this.empresasSelect = this.empresasAll;


      if (this.selProv !== undefined && this.selProv !== 'undefined') {
        if (this.selProv === 'Badajoz') {
          const cac: Localidad = this.localidadesCaceres[0];
          if (this.localidades.includes(cac)) { this.selLoc = 'undefined'; }
          this.localidades = this.localidadesBadajoz;
        }
        if (this.selProv === 'Cáceres') {
          const bad: Localidad = this.localidadesBadajoz[0];

          if (this.localidades.includes(bad)) {  this.selLoc = 'undefined'; }
          this.localidades = this.localidadesCaceres;
        }

        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.provincia === this.selProv);
      } else {
        this.localidades = this.localidadesExtremadura;
      }

      if (this.selLoc !== undefined && this.selLoc !== 'undefined') {
        this.empresasSelect = Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) => item.localidad === this.selLoc);
      }

      if (this.selFor.length > 0) {
        this.empresasSelect =  Array.from(this.empresasSelect.values()).filter((item: EmpresaCompleta) =>
        this.selFor.includes(item.formaJuridica));
      }

      this.updateData();
    }

    // tslint:disable-next-line: typedef
    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }
}

<div class="container">
    <div class="form-group">
        <h1>Contacto</h1>
        <div class="col-sm">
            <p class="pgreen">Su nombre <span class="form-required" title="Este campo es obligatorio.">*</span><br>
                <input [(ngModel)]="nombre" />
            </p>

            <p class="pgreen">Su dirección de correo electrónico <span class="form-required" title="Este campo es obligatorio.">*</span><br>
                <input [(ngModel)]="correo"  />
            </p>

            <p class="pgreen">Asunto <span class="form-required" title="Este campo es obligatorio.">*</span><br>
                <input [(ngModel)]="asunto"  />
            </p>

            <p class="pgreen">Categoría <span class="form-required" title="Este campo es obligatorio.">*</span><br>
                <select [(ngModel)]="categoria" name="categoria">
                    <option selected value="undefined" >---Por favor elija una opción---</option>
                    <option value="errores">Solicitud de Corrección de Errores</option>
                    <option value="modificacion">Solicitud de Modificación de Información</option>
                    <option value="sugerencias">Sugerencias o Propuestas de Mejora</option>
                </select>
            </p>
            
            <p class="pgreen">Mensaje <span class="form-required" title="Este campo es obligatorio.">*</span><br>
               <textarea [(ngModel)]="texto" ></textarea>
            </p>
        

            <button class="btn btn-primary" (click)="sendMail()">Enviar</button>
            <br>
            <br>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { AutenticationService } from 'src/app/services/bbdd/autentication.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

@Component({
  selector: 'app-del-empresa',
  templateUrl: './del-empresa.component.html',
  styleUrls: ['./del-empresa.component.css']
})
export class DelEmpresaComponent implements OnInit {

    // Vector que contiene el listado de empresas
    empresasAll: EmpresaCompleta[] = [];
    // Vector que almacena el listado de nombre de empresas
    titulos: string[] = [];
    // Nombre de empresa seleccionado
    selTit!: string;

    constructor(private es: EmpresasService, private as: AutenticationService,  private router: Router) { }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.es.getEmpresasComp().subscribe(
            res => {
            this.empresasAll =  res as EmpresaCompleta[];
            this.empresasAll.forEach(element => {
                this.titulos.push(element.titulo);
                this.titulos = this.titulos.sort((n1, n2) => {
                    if (n1 > n2) {
                        return 1;
                    }
                    if (n1 < n2) {
                        return -1;
                    }
                    return 0;
                });
            });
            },
            err => console.log(err)
        );

        this.as.logged.subscribe(logged => {
            if (!logged) {
            this.router.navigateByUrl('/login');
            }
        });
    }

    // Fincion controladora que elimina la empresa de la base de datos
    // tslint:disable-next-line: typedef
    delete() {
        this.es.deleteEmpresasComp(this.selTit).subscribe(res => {
            this.return();
        });
    }

    // Funcion de retorno al menu de administrador
    return(): void {
        this.router.navigateByUrl('/admin');
    }


}

<!-------------------NAVBAR -------------------->

<app-navigation></app-navigation>
<!-------------------NAVBAR -------------------->
<div class="container">
    <router-outlet></router-outlet>
</div>





import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getEmpresasComp() {
      return this.http.get(`${this.API_URI}/empresasComp`);
    }
    // tslint:disable-next-line: typedef
    getLocalidades() {
      return this.http.get(`${this.API_URI}/empresasComp/localidad`);
    }
    // tslint:disable-next-line: typedef
    getLocalidadesBadajoz() {
      return this.http.get(`${this.API_URI}/empresasComp/localidad/Badajoz`);
    }
    // tslint:disable-next-line: typedef
    getLocalidadesCaceres() {
      return this.http.get(`${this.API_URI}/empresasComp/localidad/Cáceres`);
    }
    // tslint:disable-next-line: typedef
    getConstitucion() {
      return this.http.get(`${this.API_URI}/empresasComp/constitucion`);
    }
    // tslint:disable-next-line: typedef
    getConstitucionLabels() {
      return this.http.get(`${this.API_URI}/empresasComp/constitucionLabels`);
    }
    // tslint:disable-next-line: typedef
    getDisoluccion() {
      return this.http.get(`${this.API_URI}/empresasComp/disolucion`);
    }
    // tslint:disable-next-line: typedef
    getDisoluccionLabels() {
      return this.http.get(`${this.API_URI}/empresasComp/disolucionLabels`);
    }
    // tslint:disable-next-line: typedef
    getOneEmpresasCompbyTit(tit: string) {
      return this.http.get(`${this.API_URI}/empresasComp/${tit}`);
    }
    // tslint:disable-next-line: typedef
    saveEmpresasComp(emp: EmpresaCompleta ) {
      return this.http.post(`${this.API_URI}/empresasComp`, emp);
    }
    // tslint:disable-next-line: typedef
    deleteEmpresasComp(id: string) {
      return this.http.delete(`${this.API_URI}/empresasComp/${id}`);
    }
    // tslint:disable-next-line: typedef
    updateEmpresasComp(id: string, emp: EmpresaCompleta) {
      return this.http.put(`${this.API_URI}/empresasComp/${id}`, emp);
    }
}

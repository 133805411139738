import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Correo } from '../models/Correo';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    sendNotification( not: Correo) {
      return this.http.post(`${this.API_URI}/mail`, not);
    }
}

<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    
    <img src="../../../../assets/img/taxologo.png" style="margin-right: 20px;" >

    <a class="navbar-brand" href="http://www.cenits.es/proyectos/taxonomtic-2018">OLISTIC</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
  
     <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
       
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['']" >INICIO <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              ACTIVIDADES
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['actividadesTIC']" >Actividades TIC</a>
              <a class="dropdown-item" [routerLink]="['porcentaje']" >Porcentaje de Actividades TIC</a>
            </div>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              EMPRESAS
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['directorio']">Directorio de Empresas</a>
              <a class="dropdown-item" [routerLink]="['evolucion']">Evolución</a>
              <a class="dropdown-item" [routerLink]="['mapa']">Mapa</a>
            </div>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              PERFILES Y COMPETENCIAS
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['perfiles']">Perfiles TIC</a>
              <a class="dropdown-item" [routerLink]="['competencias']">Competencias TIC</a>
              <a class="dropdown-item" [routerLink]="['encuesta']">Encuesta Profesional</a>
            </div>
        </li>

        <li class="nav-item active">
            <a class="nav-link" [routerLink]="['memorias']" >MEMORIAS <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              INFORMACIÓN
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['aviso']">Aviso Legal</a>
              <a class="dropdown-item" [routerLink]="['politica']">Política de Privacidad</a>
              <a class="dropdown-item" [routerLink]="['mapaweb']">Mapa Web</a>
              <a class="dropdown-item" [routerLink]="['contacto']">Contacto</a>
              <a class="dropdown-item" [routerLink]="['admin']">Administrador</a>
            </div>
        </li>

      </ul>
     </div> 
  
</nav>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/User';
import { AutenticationService } from 'src/app/services/bbdd/autentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    selUserL!: string;
    selPassL!: string;
    selUserR!: string;
    selPassR!: string;
    selName!: string;
    selApe!: string;

    user!: Usuario;

    usuarios: Usuario[] = [];
    public loginOK = false;

    constructor( private as: AutenticationService, private router: Router) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.as.getUsuarios().subscribe(
        res => {
            this.usuarios =  res as Usuario[];
        },
        err => console.log(err)
      );
    }

    login(): void {
      this.loginOK = false;
      this.usuarios.forEach(element => {
        if (element.usuario === this.selUserL && element.password === this.selPassL) {
          this.as.setLogged(true, element);
          this.as.user = element;
          this.loginOK = true;
          this.router.navigateByUrl('admin');
        }
      });

      if (!this.loginOK) {
        this.as.setLogged(false, this.user);
        this.router.navigateByUrl('');
      }
    }


}

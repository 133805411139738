import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActividadTIC } from 'src/app/models/ActividadTIC';
import { CategoriaTIC } from 'src/app/models/CategoriaTIC';
import { EmpAct } from 'src/app/models/EmpAct';
import { EmpresaCompleta } from 'src/app/models/EmpresaCompleta';
import { ActividadesTICService } from 'src/app/services/bbdd/actividades-tic.service';
import { AutenticationService } from 'src/app/services/bbdd/autentication.service';
import { CategoriasTICService } from 'src/app/services/bbdd/categorias-tic.service';
import { EmpActService } from 'src/app/services/bbdd/emp-act.service';
import { EmpCatService } from 'src/app/services/bbdd/emp-cat.service';
import { EmpresasService } from 'src/app/services/bbdd/empresas.service';

@Component({
  selector: 'app-edit-empresa',
  templateUrl: './edit-empresa.component.html',
  styleUrls: ['./edit-empresa.component.css']
})
export class EditEmpresaComponent implements OnInit {

    formas: string[] = ['Autónomo', 'Sociedad anónima', 'Sociedad de responsabilidad limitada',
  'Sociedad colectiva', 'Sociedad comanditaria', 'Comunidad de bienes y herencias yacentes',
  'Sociedad cooperativa', 'Asociación o fundación', 'Comunidad de propietarios en régimen de propiedad horizantal',
  'Sociedad civil, con o sin personalidad jurídica', 'Entidad extranjera', 'Corporación local',
  'Organismo público', 'Congrgación o institución religiosa', 'Órgano de la Administración del Estado y de las Comunidades Autónomas',
  'Unión temporal de empresa', 'Establecimiento permanente de entidades no residentes en España', 'Otros'];

  visible = false;

  empresasAll: EmpresaCompleta[] = [];
  titulos: string[] = [];
  selTit!: string;
  empresa: EmpresaCompleta = new EmpresaCompleta();
  titulo = '';
  CIF = '';
  descripcion = '';
  numTrabajadores = 0;
  telefono = '';
  web = '';
  correoElectronico = '';
  direccion = '';
  direccionCompleta = '';
  formaJuridica = '';
  constitucion = '';
  disolucion = '';
  puraTIC!: string;
  localidad  = '';
  provincia!: string;
  ubicacion = '';
  latitud = 0;
  longitud = 0;
  empresaID!: number;

  categorias: CategoriaTIC[] = [];
  actividades: ActividadTIC[] = [];
  selCat: CategoriaTIC[] = [];
  selAct: ActividadTIC[] = [];
  relEmpActAll: EmpAct[] = [];
  relEmpActSel: EmpAct[] = [];
  catids: number[] = new Array();

  constructor(private ea: EmpActService, private ec: EmpCatService, private es: EmpresasService,
              private cTICs: CategoriasTICService, private aTICs: ActividadesTICService, private as: AutenticationService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.as.logged.subscribe(logged => {
      if (!logged) {
        this.router.navigateByUrl('/login');
      }
    });

    this.cTICs.getCategoriasTIC().subscribe(
      res => {
        this.categorias = res as CategoriaTIC[];
      },
      err => console.log(err)
    );

    this.aTICs.getActividadesTIC().subscribe(
      res => {
        this.actividades = res as ActividadTIC[];
      },
      err => console.log(err)
    );

    this.ea.getRelations().subscribe(
        res => {
          this.relEmpActAll = res as EmpAct[];
        }
      );

    this.es.getEmpresasComp().subscribe(
      res => {
        this.empresasAll = res as EmpresaCompleta[];
        this.empresasAll.forEach(element => {
          this.titulos.push(element.titulo);
          this.titulos = this.titulos.sort((n1, n2) => {
            if (n1 > n2) {
                return 1;
            }
            if (n1 < n2) {
                return -1;
            }
            return 0;
        });
        });
        this.selTit = this.route.snapshot.paramMap.get('titulo') as string;
        console.log(this.selTit);
        if (this.selTit === 'default') {
            this.selTit = this.empresasAll[0].titulo;
        }
        this.visible = true;
        this.loadInfo();
      },
      err => console.log(err)
    );



  }

  edit(): void {

    this.empresa.CIF = this.CIF;
    this.empresa.constitucion = this.constitucion;
    this.empresa.correoElectronico = this.correoElectronico;
    this.empresa.descripcion = this.descripcion;
    this.empresa.direccionCompleta = this.direccionCompleta;
    this.empresa.disolucion = this.disolucion;
    this.empresa.formaJuridica = this.formaJuridica;
    this.empresa.localidad = this.localidad;
    this.empresa.longitud = this.longitud;
    this.empresa.latitud = this.latitud;
    this.empresa.num_trabajadores = this.numTrabajadores;
    this.empresa.provincia = this.provincia;
    this.empresa.puraTIC = this.puraTIC;
    this.empresa.telefono = this.telefono;
    this.empresa.titulo = this.titulo;
    this.empresa.ubicacion = this.direccion;
    this.empresa.web = this.web;

    this.ea.deleteRelation(this.empresaID.toString()).subscribe();

    this.selAct.forEach( act => {
      const relAct: EmpAct = new EmpAct();
      relAct.actividadID = act.actividadID as number;
      relAct.empresaID = this.empresaID;
      this.ea.saveRelation(relAct).subscribe();
    });

    this.es.updateEmpresasComp(this.empresaID.toString(), this.empresa).subscribe();
    this.delay(300);
    this.resetForm();
    this.return();
  }

  return(): void {
    this.router.navigateByUrl('/admin');
  }

  resetForm(): void {
    this.titulo = '';
    this.CIF = '';
    this.descripcion  = '';
    this.numTrabajadores = 0;
    this.telefono = '';
    this.web = '';
    this.correoElectronico = '';
    this.direccion = '';
    this.direccionCompleta = '';
    this.formaJuridica = '';
    this.constitucion = '';
    this.disolucion = '';
    this.localidad = '';
    this.ubicacion = '';
    this.latitud = 0;
    this.longitud = 0;
    while (this.selAct.length > 0) {
      this.selAct.pop();
    }
  }

  loadInfo(): void {

    let emp: EmpresaCompleta;
    this.titulo = this.selTit;
    emp = Array.from(this.empresasAll.values()).filter((item: EmpresaCompleta) =>
    item.titulo === this.selTit)[0];
    this.empresaID = emp.empresaID as number;

    this.relEmpActSel = Array.from(this.relEmpActAll.values()).filter((item: EmpAct) =>
    item.empresaID === this.empresaID);

    this.CIF = emp.CIF;
    this.descripcion  = emp.descripcion;
    this.numTrabajadores = emp.num_trabajadores;
    this.telefono = emp.telefono;
    this.web = emp.web;
    this.correoElectronico = emp.correoElectronico;
    this.direccion = emp.direccion;
    this.direccionCompleta = emp.direccionCompleta;
    this.formaJuridica = emp.formaJuridica;
    this.constitucion = emp.constitucion;
    this.disolucion = emp.disolucion;
    this.localidad = emp.localidad;
    this.ubicacion = emp.ubicacion;
    this.latitud = emp.latitud;
    this.longitud = emp.longitud;
    this.puraTIC = emp.puraTIC;
    this.provincia = emp.provincia;

    this.updateSelAct();
  }

  updateSelAct(): void {
    const acts: number[] = [];
    this.relEmpActSel.map(x => {acts.push(x.actividadID); });
    this.selAct = Array.from(this.actividades.values()).filter((item: ActividadTIC) => 
    acts.includes(item.actividadID as number));
    console.log(this.selAct);
  }


  empChange(): void {

      if (this.selTit !== undefined && this.selTit !== 'undefined') {
        this.visible = true;
        this.loadInfo();
      } else {
        this.visible = false;
      }
  }

  // tslint:disable-next-line: typedef
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}

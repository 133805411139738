import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mensaje } from 'src/app/models/Mensaje';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

    API_URI = environment.server_address;

    constructor(private http: HttpClient) { }

    // tslint:disable-next-line: typedef
    getMensajes() {
      return this.http.get(`${this.API_URI}/mensajes`);
    }
    // tslint:disable-next-line: typedef
    saveMensaje(msg: Mensaje ) {
      return this.http.post(`${this.API_URI}/mensajes`, msg);
    }
}

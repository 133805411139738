import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainview',
  templateUrl: './mainview.component.html',
  styleUrls: ['./mainview.component.css']
})
export class MainviewComponent implements OnInit {

    titulo = 'TaxoTIC';

    texto1 = 'Las Tecnologías de la Información y la Comunicación (TIC) se han transformado en la herramienta de desarrollo más importante del siglo XXI. El conjunto de empresas y profesionales que proveen dichas tecnologías representan un sector importante en la actualidad y fundamental para el futuro. La correcta identificación de las empresas TIC en Extremadura y un estudio de la evolución e influencia económica del sector en la región, constituyen una herramienta valiosa para una adecuada toma de decisiones que potencie y fortalezca el futuro del mismo.';

    texto2 = 'El objetivo principal de este proyecto consiste en identificar las empresas TIC extremeñas y construir un sistema bajo la filosofía open data que permita localizar las empresas del sector y los servicios que ofrecen.';


    constructor() { }

    ngOnInit(): void {
        window.scrollTo(0, 0);
}

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticationService } from 'src/app/services/bbdd/autentication.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    constructor(private as: AutenticationService,  private router: Router) { }

    ngOnInit(): void {
      window.scrollTo(0, 0);
      this.as.logged.subscribe(logged => {
        if (!logged) {
          this.router.navigateByUrl('login');
        }
      });
    }

    // Cambio al formulario de añadir empresas
    addEmpresa(): void {
        this.router.navigate(['admin/addEmpresa']);
    }
    // Cambio al formulario de edición de empresas
    editEmpresa(): void {
      this.router.navigate(['admin/editEmpresa', 'default']);
    }
    // Cambio al formulario de eliminación de empresas
    delEmpresa(): void {
      this.router.navigate(['admin/delEmpresa']);
    }

}
